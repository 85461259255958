import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import useUserDetailHook from "../../utils/useUserDetailHook";
import ReactGA from "react-ga4";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import styles from "../../styles/pages.module.css";

const ShowResourceFormData = () => {
  const [data, setData] = useState([]);
  const [userEmailId, username] = useUserDetailHook();

  const Navigate = useNavigate();

  useEffect(() => {
    ReactGA.set({ user_id: username });
    document.title = "Show Resource Form Data";
  }, [username]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/getresourceformdata`)
      .then((res) => setData(res.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <Navbar />
      <div className="container">
        <h3>Resource Form Data</h3>
        <table className={`${styles.table} ${styles.one_skill_table}`}>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>CRM ID</th>
              <th>Project Name</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "center" }}>
                  No data available for now!
                </td>
              </tr>
            ) : (
              data.map((d, index) => (
                <tr key={index}>
                  <td>{d.resource.ClientName}</td>
                  <td>{d.resource.CRMID}</td>
                  <td>{d.resource.ProjectName}</td>
                  <td>
                    <button
                      className={styles.details_btn}
                      onClick={() =>
                        Navigate(`/show-resource-form/${d.resource.ClientName}`)
                      }
                    >
                      More &rarr;
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-sm-between mt-4">
          <Link to="/" className={`btn btn-secondary`}>
            Back
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShowResourceFormData;
