import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { useMsal } from "@azure/msal-react";
import useUserDetailHook from "../utils/useUserDetailHook";
import { loginRequest } from "../authConfig";
import { sendMail } from "../utils/sendMail";
import ReactGA from "react-ga4";
import axios from "axios";

const SolutionBlueprint = () => {
  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  const [HRMID, setHRMID] = useState("");
  const [link, setLink] = useState("");
  const [desc, setDesc] = useState("");

  useEffect(() => {
    ReactGA.set({ user_id: username });
    document.title = "Share Solution Blueprint ( Case Study )";
  }, [username]);

  const apiCall = () => {
    const data = {
      Name: username,
      HRMID: HRMID,
      Link: link,
      Description: desc,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/updateExcel`,
        {
          data: data,
          sheetName: "solution_blueprint",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      // .then((res) => console.log(res))
      .catch((error) => console.log(error));
  };

  const submitHandler = (e) => {
    const solution_blueprint_form = document.getElementById("solution");

    e.preventDefault();

    const body = `Hello,\n\n
      Name: ${username} \n
      HRMID: ${HRMID} \n
      Downloadable Link: ${link} \n
      Description: ${desc} \n
    `;

    let subject = `[Solution Blueprint] Form Submission by - ${username}`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then(
          (response) => {
            if (
              response &&
              (response.status === 202 || response.status === 200)
            ) {
              alert("Data send Successfully!");
            }
          }
        );
      });

    apiCall();

    setHRMID("");
    setLink("");
    setDesc("");
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <h3 style={{ marginBottom: "1rem" }}>
          Share Solution Blueprint ( Case Study )
        </h3>
        <form id="solution" className="form row" onSubmit={submitHandler}>
          <div className="col-12">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={username}
              required
              disabled
            />
          </div>
          <div className="col-12">
            <label htmlFor="hrmid">HRMID:</label>
            <input
              type="text"
              id="hrmid"
              name="hrmid"
              value={HRMID}
              onChange={(e) => setHRMID(e.target.value)}
              required
            />
          </div>
          <p>
            Please Download the template for the document, and modify with your
            datas.
            <a
              href="https://celebaltech-my.sharepoint.com/:w:/p/coe/EaY0XO_prPlPqEm23UCHVsUBTAWUKG3JTisLeClTwx4Lrg?e=kqz33z"
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Link to the template
            </a>
          </p>
          <div className="col-12">
            <label htmlFor="link">Sharable Link to the Document:</label>
            <input
              type="text"
              id="link"
              name="link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              required
            />
          </div>
          <div className="col-12">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              rows="5"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="d-flex justify-content-sm-between mt-4">
            <Link to="/" className="btn btn-secondary add-form-btn">
              Back
            </Link>
            <button type="submit" className="btn btn-primary add-form-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default SolutionBlueprint;
