import styles from "../styles/pages.module.css";

const CohortDE = () => {
  return (
    <div className="container">
      <h4 style={{ textAlign: "center" }}>
        Monthly Certification Cohort for Data Engineering ( October 2023 )
      </h4>
      <div className={styles.table}>
        <thead>
          <tr>
            <th className={styles.cohort}>Monday</th>
            <th className={styles.cohort}>Tuesday</th>
            <th className={styles.cohort}>Wednesday</th>
            <th className={styles.cohort}>Thursday</th>
            <th className={styles.cohort}>Friday</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="5">
              <div className={styles.table_merged}>
                <span></span>
                <span>03</span>
                <span>04</span>
                <span>05</span>
                <span>06</span>
              </div>
              <p className={styles.table_para}>Nomination for Cohort</p>
            </td>
          </tr>
          <tr className={styles.grey}>
            <td colspan="5">
              <div className={styles.table_merged}>
                <span>09</span>
                <span>10</span>
                <span>11</span>
                <span>12</span>
                <span>13</span>
              </div>
              <p className={styles.table_para}>
                15 Free Vouchers Distributed Every Week After Evaluation{" "}
                <a
                  href="https://gyan.celebaltech.com/certification-workshops/celebal/de/index.html"
                  className={styles.link}
                  target="_blank"
                >
                  ( Videos Available on Gyan Portal )
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="5">
              <div className={styles.table_merged}>
                <span>16</span>
                <span>17</span>
                <span>18</span>
                <span>19</span>
                <span>20</span>
              </div>
              <p className={styles.table_para}>
                15 Free Vouchers Distributed Every Week After Evaluation{" "}
                <a
                  href="https://gyan.celebaltech.com/certification-workshops/celebal/de/index.html"
                  className={styles.link}
                  target="_blank"
                >
                  ( Videos Available on Gyan Portal )
                </a>
              </p>
            </td>
          </tr>
          <tr className={styles.grey}>
            <td colspan="5">
              <div className={styles.table_merged}>
                <span>23</span>
                <span>24</span>
                <span>25</span>
                <span>26</span>
                <span>27</span>
              </div>
              <p className={styles.table_para}>
                15 Free Vouchers Distributed Every Week After Evaluation{" "}
                <a
                  href="https://gyan.celebaltech.com/certification-workshops/celebal/de/index.html"
                  className={styles.link}
                  target="_blank"
                >
                  ( Videos Available on Gyan Portal )
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="5">
              <div className={styles.table_merged}>
                <span>30</span>
                <span>31</span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </td>
          </tr>
        </tbody>
      </div>
    </div>
  );
};

export default CohortDE;
