export const DateAndTimeSept = {
  _01st_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _04th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _05th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _06th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _07th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _08th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _11th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _12th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _13th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _14th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _15th_September: ["12:00 PM - 01:00 PM"],
  _18th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _19th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _20th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _21th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _22nd_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _25th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _26th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _27th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _28st_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _29th_September: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
};
