import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { sendMail } from "../../utils/sendMail";
import { DateAndTimeMay } from "../../data/DateAndTimeMay";
import useUserDetailHook from "../../utils/useUserDetailHook";
import ReactGA from "react-ga4";
import styles from "../../styles/pages.module.css";

const OneSkillEverydayMay = () => {
  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  useEffect(() => {
    ReactGA.set({ user_id: username });
    document.title = "One Skill Everyday May";
  }, [username]);

  const [name, setName] = useState("");
  const [HRMID, setHRMID] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [timeList, setTimeList] = useState([]);
  const [title, setTitle] = useState("");

  const datesArray = Object.keys(DateAndTimeMay).map(date => {
    const day = date.slice(1, 3);
    const month = date.slice(3).replace("_", " ");
    return `${day}${month}`;
  });

  const dateHandler = (e) => {
    setDate(e.target.value);
    let newDate = "_" + e.target.value.replace(/\s/g, "_");
    setTimeList(DateAndTimeMay[newDate]);
    setTime("");
  }

  const timeHandler = (e) => {
    setTime(e.target.value);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    const body = `Hello,\n\n
    Name: ${name} \n   
    HRMID: ${HRMID} \n
    Date: ${date} \n
    Time: ${time} \n
    Title: ${title} \n`;

    let subject = `[May Schedule] Submission by - ${name}`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then((response) => {
          if (response && (response.status === 202 || response.status === 200)) {
            alert("Data send Successfully!");
          }
        });
      });

    setName("");
    setHRMID("");
    setDate("");
    setTime("");
    setTitle("");
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <h4>Submit your entry for May One Skill Everyday Sessions</h4>
        <form className="row" onSubmit={sendEmail}>
          <div className="col-6">
            <label htmlFor="name">Name</label>
            <input value={name} type="text" className="form-control" id="name" placeholder="Presenter Name" onChange={(e) => setName(e.target.value)} required />
          </div>
          <div className="col-6">
            <label htmlFor="hrmid">HRM ID</label>
            <input onChange={(e) => setHRMID(e.target.value)} value={HRMID} type="text" className="form-control" id="HRMID" placeholder="HRM ID" required />
          </div>
          <div className="col-6">
            <label htmlFor="date">Date</label>
            <select name="date" id="date" className="form-control" value={date} required onChange={dateHandler}>
              <option selected disabled value="">
                Choose...
              </option>
              {datesArray.map((date, index) => {
                return (
                  <option value={date} key={index}>
                    {date}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="col-6">
            <label htmlFor="time">Time</label>
            <select name="time" id="time" className="form-control" value={time} required onChange={timeHandler}>
              <option selected disabled value="">
                Choose...
              </option>
              {timeList.map((time, index) => {
                return (
                  <option value={time} key={index}>
                    {time}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="col-12">
            <label htmlFor="title">Title</label>
            <input onChange={(e) => setTitle(e.target.value)} value={title} type="text" className="form-control" id="title" placeholder="Title of session" required />
          </div>
          <button type="submit" className={`btn btn-primary ${styles.submit_btn}`}>
            Submit
          </button>
        </form>
        <h4>May Calendar</h4>
        <table className={`${styles.table} ${styles.one_skill_table}`}>
          <thead>
            <tr>
              <th className={styles.date}>Date</th>
              <th className={styles.day}>Day</th>
              <th className={styles.time}>Time</th>
              <th className={styles.employee}>Presenter</th>
              <th>Title</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01st May 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>02nd May 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>03rd May 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>04th May 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>05th May 2023</td>
              <td>Friday</td>
              <td>05:00 PM - 06:00 PM</td>
              <td>Nikhil Garg</td>
              <td className={styles.topic}>Incremental load using Adf with all the challenges</td>
            </tr>
            <tr className={styles.grey}>
              <td>06th May 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>07th May 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>08th May 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>09th May 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td><s>10th May 2023</s></td>
              <td><s>Wednesday</s></td>
              <td><s>05:00 PM - 06:00 PM</s></td>
              <td><s>Mehul Sherdiwala</s></td>
              <td className={styles.topic}><s>Streamline your data pipeline with Delta Live Table</s></td>
            </tr>
            <tr className={styles.grey}>
              <td>11th May 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>12th May 2023</td>
              <td>Friday</td>
              <td><del>12:00 PM - 01:00 PM</del> <hr />05:00 PM - 06:00 PM</td>
              <td><del>Mayank Jhamb</del> <hr />Vivek Singh Rawat</td>
              <td className={styles.topic}><del>Hash Map, Paralelism in Multithreading, Notebook to notebook synchronization</del> <hr /> Orchestration Through Apache Airflow Basics</td>
            </tr>
            <tr className={styles.grey}>
              <td><del>13th May 2023</del></td>
              <td><del>Saturday</del></td>
              <td><del>05:00 PM - 06:00 PM</del></td>
              <td><del>Kashetti Prashanth</del></td>
              <td className={styles.topic}><del>MLOps with Databricks</del></td>
            </tr>
            <tr className={styles.sunday}>
              <td>14th May 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>15th May 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td><del>16th May 2023</del></td>
              <td><del>Tuesday</del></td>
              <td><del>05:00 PM - 06:00 PM</del></td>
              <td><del>Chandan Jangid</del></td>
              <td className={styles.topic}><del>MLOps using AML Studio & Azure DevOps</del></td>
            </tr>
            <tr>
              <td>17th May 2023</td>
              <td>Wednesday</td>
              <td>12:00 PM - 01:00PM <hr />05:00 PM - 06:00 PM</td>
              <td>Gourav Jangid <hr /> Amit Kumawat</td>
              <td className={styles.topic}>Databricks Connect using Jupyter and PyCharm for Lower Versions of Runtime &lt; 13.<hr />Dynamic SQL</td>
            </tr>
            <tr className={styles.grey}>
              <td>18th May 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>19th May 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>20th May 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>21st May 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>22nd May 2023</td>
              <td>Monday</td>
              <td>05:00 PM - 06:00 PM</td>
              <td>Akash Tyagi</td>
              <td className={styles.topic}>Nifi: An Orchestrating Tool</td>
            </tr>
            <tr className={styles.grey}>
              <td>23rd May 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>24th May 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td><del>25th May 2023</del></td>
              <td><del>Thursday</del></td>
              <td><del>05:00 PM - 06:00 PM</del></td>
              <td><del>Rishabh Pandey</del></td>
              <td className={styles.topic}><del>Account & workspace administration</del></td>
            </tr>
            <tr>
              <td>26th May 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td><del>27th May 2023</del></td>
              <td><del>Saturday</del></td>
              <td><del>05:00 PM - 06:00 PM</del></td>
              <td><del>Ajay Patni</del></td>
              <td className={styles.topic}><del>Data governance using Table ACLS unity catalog and SQL endpoint</del></td>
            </tr>
            <tr className={styles.sunday}>
              <td>28th May 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>29th May 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>30th May 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>31st May 2023</td>
              <td>Wednesday</td>
              <td>06:00 PM - 07:00 PM</td>
              <td>Pankaj Kumar</td>
              <td className={styles.topic}>Dynamic Expressions and Container in SSIS</td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex justify-content-sm-between mt-4">
          <Link to="/" className={`btn btn-secondary ${styles.calendar_btn}`}>
            Back
          </Link>
        </div>
      </div >
      <Footer />
    </>
  );
};

export default OneSkillEverydayMay;
