import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import MultipleNominations from "../components/Form/MultipleNominations";
import CohortDE from "./CohortDE";

const NominationDE = () => {
  return (
    <>
      <Navbar />
      <CohortDE />
      <MultipleNominations
        title=" Nomination for DE Professional Certification Preparation Monthly Cohort ( October )"
        SheetName="oct_de_nomination"
      />
      <Footer />
    </>
  );
};

export default NominationDE;
