import React, { useEffect } from "react";

const ResourceData = ({ clientData }) => {
  useEffect(() => {
    localStorage.setItem("ClientName", clientData[0].resource.ClientName);
  }, []);

  return (
    <div className="row" style={{ marginTop: "2rem", marginLeft: "2rem" }}>
      <div className="col-6">
        <div className="row">
          <div className="col-6" style={{ fontWeight: "600" }}>
            <p>Name</p>
            <p>HRMID</p>
            <p>Client Name</p>
            <p>Project Name</p>
            <p>Project Domain</p>
            <p>Existing Project</p>
            <p>Region</p>
            <p>Country</p>
            {clientData[0].resource.City && <p>City</p>}
            {clientData[0].resource.State && <p>State</p>}
            <p>CRMID</p>
            <p>Zoho Project ID</p>
            <p>Client Partner (Account Manager)</p>
          </div>
          <div className="col-6">
            <p>{clientData[0].resource.Name}</p>
            <p>{clientData[0].resource.HRMID}</p>
            <p>{clientData[0].resource.ClientName}</p>
            <p>{clientData[0].resource.ProjectName}</p>
            <p>{clientData[0].resource.ProjectDomain}</p>
            <p>{clientData[0].resource.ExistingProject}</p>
            <p>{clientData[0].resource.Region}</p>
            <p>{clientData[0].resource.Country}</p>
            {clientData[0].resource.City && (
              <p>{clientData[0].resource.City}</p>
            )}
            {clientData[0].resource.State && (
              <p>{clientData[0].resource.State}</p>
            )}
            <p>{clientData[0].resource.CRMID}</p>
            <p>{clientData[0].resource.ZohoProjectID}</p>
            <p>{clientData[0].resource.ClientPartner}</p>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="row">
          <div className="col-8" style={{ fontWeight: "600" }}>
            <p>Account Operations (Sales Representative)</p>
            <p>Delivery Partner (Tech Lead)</p>
            <p>Competency Partner (Presales Solution Architect)</p>
            <p>Project Type</p>
            <p>Engagement Type</p>
            <p>Architecture Document Upload</p>
            <p>Proposed Techical Solution Document Upload</p>
            <p>Technical Use Case Description</p>
            <p>Business Use Case Description</p>
            <p>Proposed Techical Solution Description</p>
            <p>Remarks</p>
          </div>
          <div className="col-4">
            <p>{clientData[0].resource.AccountOperations}</p>
            <p>{clientData[0].resource.DelieveryPartner}</p>
            <p>{clientData[0].resource.ComptencyPartner}</p>
            <p>{clientData[0].resource.ProjectType}</p>
            <p>{clientData[0].resource.EngagementType}</p>
            <p>{clientData[0].resource.ArchitectureDoc}</p>
            <p>{clientData[0].resource.ProposedTechnicalSolution}</p>
            <p>{clientData[0].resource.TechnicaUseCase}</p>
            <p>{clientData[0].resource.BuisnessUseCase}</p>
            <p>{clientData[0].resource.ProposedTechnical}</p>
            <p>{clientData[0].resource.Remarks}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceData;
