import MultipleNominations from "../components/Form/MultipleNominations";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const NominationCommunication = () => {
  return (
    <>
      <Navbar />
      <MultipleNominations
        title="Nomination for Interview Communication Preparation Weekly Cohort ( October )"
        SheetName="oct_communication_nomination"
      />
      <Footer />
    </>
  );
};

export default NominationCommunication;
