export async function sendMail(subject, content, accessToken, userEmail) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", bearer);

  let to = process.env.REACT_APP_MAIL_TO;
  let cc = process.env.REACT_APP_MAIL_CC;
  let bcc = userEmail;

  var body = JSON.stringify({
    message: {
      subject,
      body: {
        contentType: "Text",
        content,
      },
      toRecipients: [
        {
          emailAddress: {
            address: to,
          },
        },
      ],
      ccRecipients: [
        {
          emailAddress: {
            address: cc,
          },
        },
      ],
      bccRecipients: [
        {
          emailAddress: {
            address: bcc,
          },
        },
      ],
    },
    saveToSentItems: "false",
  });

  var requestOptions = {
    method: "POST",
    headers,
    body,
    redirect: "follow",
  };

  return fetch("https://graph.microsoft.com/v1.0/me/sendMail", requestOptions)
    .then((response) => response)
    .catch((error) => console.log("error", error));
}
