export const DateAndTimeJuly = {
  _03rd_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _04th_July: ["05:00 PM - 06:00 PM"],
  _05th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _06th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _07th_July: ["05:00 PM - 06:00 PM"],
  _10th_July: ["12:00 PM - 01:00 PM"],
  _11th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _12th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _13th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _14th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _17th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _18th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _19th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _20th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _21st_July: ["05:00 PM - 06:00 PM"],
  _24th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _25th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _26th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _27th_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _31st_July: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
};
