export const DateAndTimeAugust = {
  _01st_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _02nd_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _03rd_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _04th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _07th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _08th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _09th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _10th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _11th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _14th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _16th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _17th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _18th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _21th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _22th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _23th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _24th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _25th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _28th_August: ["12:00 PM - 01:00 PM"],
  _29th_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _31st_August: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
};
