import React from "react";
import styles from "../styles/Footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div className={styles.footer_container}>
        <span className={styles.footer_heading}>
          &#169; 2024, Celebal Technologies.
        </span>
        <span className={`${styles.footer_url_blue} ps-1`}>
          All rights reserved.
        </span>
      </div>
    </footer>
  )
};

export default Footer;
