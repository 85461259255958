import React, { useState } from "react";
import ApplyModal from "./ApplyModal";

const RolesData = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="row" style={{ marginTop: "2rem", marginLeft: "2rem" }}>
      <div className="col-6">
        <div className="row">
          <div className="col-6" style={{ fontWeight: "600" }}>
            <p>Role</p>
            <p>Tech Skills Summary</p>
            <p>Mode</p>
            <p>Work Experience</p>
            <p>CT Work Experience</p>
            <p>Head Count</p>
            <p>Work Location</p>
            {data.WorkLocation === "Onsite" ? (
              <>
                <p>Region</p>
                <p>Country</p>
                <p>City</p>
              </>
            ) : (
              <p>Office Location</p>
            )}
            <p>Preferred Employment Type</p>
            <p>Shift Time</p>
            <p>Overlap Required</p>
          </div>
          <div className="col-6">
            <p>{data.Role}</p>
            <p>{data.TechSkills}</p>
            <p>{data.Mode}</p>
            <p>{data.WorkExperience}</p>
            <p>{data.CTWorkExperience}</p>
            <p>{data.HeadCount}</p>
            <p>{data.WorkLocation}</p>
            {data.WorkLocation === "Onsite" ? (
              <>
                <p>{data.Region}</p>
                <p>{data.Country}</p>
                <p>{data.City}</p>
              </>
            ) : (
              <p>{data.OfficeLocation}</p>
            )}
            <p>{data.PreferredEmploymentType}</p>
            <p>{data.ShiftTime}</p>
            <p>{data.OverlapRequired}</p>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="row">
          <div className="col-8" style={{ fontWeight: "600" }}>
            <p>Interview Mandatory (Client / Partner)</p>
            <p>Budget Range ( CTC per month/ per hour )</p>
            <p>Mapping Time Period</p>
            <p>Utilization (as per man hours per day)</p>
            <p>Minimum Engagement Period (anticipated)</p>
            <p>Max Engagement Period (anticipated)</p>
            <p>Engagement Tentative Start Date</p>
            <p>Engagement Tentative End Date</p>
            <p>Certification Requirenment</p>
            <p>Resource could be doing in a Typical Day</p>
            <p>Technical Skills</p>
            <p>Behavioural Skills</p>
          </div>
          <div className="col-4">
            <p>{data.InterviewMandatory}</p>
            <p>{data.BudgetRange}</p>
            <p>{data.MappingTimePeriod}</p>
            <p>{data.Utilization}</p>
            <p>{data.MinimumEngagementPeriod}</p>
            <p>{data.MaxEngagementPeriod}</p>
            <p>{data.EngagementTentativeStartDate}</p>
            <p>{data.EngagementTentativeEndDate}</p>
            <p>{data.CertificationRequirenment}</p>
            <p>{data.ResourceInTypicalDay}</p>
            <p>{data.TechnicalSkills}</p>
            <p>{data.BehaviouralSkills}</p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <button onClick={() => setModalOpen(true)}>Apply for this role</button>
      </div>
      {modalOpen && (
        <ApplyModal
          modalOpen={modalOpen}
          setOpenModal={setModalOpen}
          role={data.Role}
        />
      )}
    </div>
  );
};

export default RolesData;
