import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { sendMail } from "../../utils/sendMail";
import { DateAndTimeJune } from "../../data/DateAndTimeJune";
import useUserDetailHook from "../../utils/useUserDetailHook";
import ReactGA from "react-ga4";
import styles from "../../styles/pages.module.css";

const OneSkillEverydayJune = () => {
  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  useEffect(() => {
    ReactGA.set({ user_id: username });
    document.title = "One Skill Everyday June";
  }, [username]);

  const [name, setName] = useState("");
  const [HRMID, setHRMID] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [timeList, setTimeList] = useState([]);
  const [title, setTitle] = useState("");

  const datesArray = Object.keys(DateAndTimeJune).map((date) => {
    const day = date.slice(1, 3);
    const month = date.slice(3).replace("_", " ");
    return `${day}${month}`;
  });

  const dateHandler = (e) => {
    setDate(e.target.value);
    let newDate = "_" + e.target.value.replace(/\s/g, "_");
    setTimeList(DateAndTimeJune[newDate]);
    setTime("");
  };

  const timeHandler = (e) => {
    setTime(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const body = `Hello,\n\n
    Name: ${name} \n   
    HRMID: ${HRMID} \n
    Date: ${date} \n
    Time: ${time} \n
    Title: ${title} \n`;

    let subject = `[June Schedule] Submission by - ${name}`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then(
          (response) => {
            if (
              response &&
              (response.status === 202 || response.status === 200)
            ) {
              alert("Data send Successfully!");
            }
          }
        );
      });

    setName("");
    setHRMID("");
    setDate("");
    setTime("");
    setTitle("");
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <h4>Submit your entry for June One Skill Everyday Sessions</h4>
        <form className="row" onSubmit={sendEmail}>
          <div className="col-6">
            <label htmlFor="name">Name</label>
            <input
              value={name}
              type="text"
              className="form-control"
              id="name"
              placeholder="Presenter Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-6">
            <label htmlFor="hrmid">HRM ID</label>
            <input
              onChange={(e) => setHRMID(e.target.value)}
              value={HRMID}
              type="text"
              className="form-control"
              id="HRMID"
              placeholder="HRM ID"
              required
            />
          </div>
          <div className="col-6">
            <label htmlFor="date">Date</label>
            <select
              name="date"
              id="date"
              className="form-control"
              value={date}
              required
              onChange={dateHandler}
            >
              <option selected disabled value="">
                Choose...
              </option>
              {datesArray.map((date, index) => {
                return (
                  <option value={date} key={index}>
                    {date}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-6">
            <label htmlFor="time">Time</label>
            <select
              name="time"
              id="time"
              className="form-control"
              value={time}
              required
              onChange={timeHandler}
            >
              <option selected disabled value="">
                Choose...
              </option>
              {timeList.map((time, index) => {
                return (
                  <option value={time} key={index}>
                    {time}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12">
            <label htmlFor="title">Title</label>
            <input
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              type="text"
              className="form-control"
              id="title"
              placeholder="Title of session"
              required
            />
          </div>
          <button
            type="submit"
            className={`btn btn-primary ${styles.submit_btn}`}
          >
            Submit
          </button>
        </form>
        <h4>June Calendar</h4>
        <table className={`${styles.table} ${styles.one_skill_table}`}>
          <thead>
            <tr>
              <th className={styles.date}>Date</th>
              <th className={styles.day}>Day</th>
              <th className={styles.time}>Time</th>
              <th className={styles.employee}>Presenter</th>
              <th>Title</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01st June 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>02nd June 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>03rd June 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>04th June 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>05th June 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>06th June 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>07th June 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>08th June 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>09th June 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>10th June 2023</td>
              <td>Saturday</td>
              <td>05:00 PM - 06:00 PM</td>
              <td>Ajay Patni</td>
              <td className={styles.topic}>
                Data governance using Table ACLS unity catalog and SQL endpoint
              </td>
            </tr>
            <tr className={styles.sunday}>
              <td>11th June 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>12th June 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>13th June 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>14th June 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>15th June 2023</td>
              <td>Thursday</td>
              <td>12:00 PM - 01:00 PM</td>
              <td>Raman Bajetha</td>
              <td className={styles.topic}>Azure purview</td>
            </tr>
            <tr className={styles.grey}>
              <td>16th June 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>17th June 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>18th June 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>19th June 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>20th June 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>21st June 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td><del>22nd June 2023</del></td>
              <td><del>Thursday</del></td>
              <td><del>05:00 PM - 06:00 PM</del></td>
              <td><del>Prachi Porwal</del></td>
              <td className={styles.topic}><del>Streaming and workflows</del></td>
            </tr>
            <tr className={styles.grey}>
              <td>23rd June 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>24th June 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>25th June 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>26th June 2023</td>
              <td>Monday</td>
              <td>05:00 PM - 06:00 PM</td>
              <td>Rohan Singh</td>
              <td className={styles.topic}>Delta Sharing and Debug Pdb</td>
            </tr>
            <tr>
              <td>27th June 2023</td>
              <td>Tuesday</td>
              <td>12:00 PM - 01:00 PM</td>
              <td>Bhawna Chaurasia</td>
              <td className={styles.topic}>DLT and it's join ( stream , static )</td>
            </tr>
            <tr className={styles.grey}>
              <td>28th June 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>29th June 2023</td>
              <td>Thursday</td>
              <td>05:00 PM - 06:00 PM</td>
              <td>Vikram Kumar</td>
              <td className={styles.topic}>
                External and Managed tables on Avro files using Hive syntax and
                Databricks
              </td>
            </tr>
            <tr className={styles.grey}>
              <td>30th June 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex justify-content-sm-between mt-4">
          <Link to="/" className={`btn btn-secondary ${styles.calendar_btn}`}>
            Back
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OneSkillEverydayJune;
