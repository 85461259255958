import { Route, Routes } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import Main from "./pages/Main";
import ClientInterview from "./pages/ClientInterview";
import CertificationQuestions from "./pages/CertificationQuestions";
import SolutionBlueprint from "./pages/SolutionBlueprint";
import ResourceForm from "./pages/Resource Form/ResourceForm";
import OneSkillEverydayApril from "./pages/One Skill Everyday/OneSkillEverydayApril";
import OneSkillEverydayMay from "./pages/One Skill Everyday/OneSkillEverydayMay";
import OneSkillEverydayJune from "./pages/One Skill Everyday/OneSkillEverydayJune";
import OneSkillEverydayJuly from "./pages/One Skill Everyday/OneSkillEverydayJuly";
import OneSkillEverydayAugust from "./pages/One Skill Everyday/OneSkillEverydayAugust";
import OneSkillEverydaySept from "./pages/One Skill Everyday/OneSkillEverydaySept";
import OneSkillEverydayOct from "./pages/One Skill Everyday/OneSkillEverydayOct";
import Login from "./components/LoginPage/LoginPage";
import NominationTechSkill from "./pages/Nomination_Interview_Tech";
import NominationCommunication from "./pages/NominationCommunication";
import NominationDE from "./pages/NominationDE";
import NominationMLCohort from "./pages/NominationMLCohort";
import AvailableResource from "./pages/Resource Form/AvailableResource";
import ShowResourceFormData from "./pages/Resource Form/ShowResourceFormData";
import ResourceFormDetails from "./pages/Resource Form/ResourceFormDetails";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import ReactGA from "react-ga4";
import "./App.css";

const TrackingID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TrackingID);

ReactGA.send({ hitType: "pageview", page: "/", title: "Main" });
ReactGA.send({
  hitType: "pageview",
  page: "/client_interview",
  title: "Client Interview",
});
ReactGA.send({
  hitType: "pageview",
  page: "/certification_questions",
  title: "Certification Questions",
});
ReactGA.send({
  hitType: "pageview",
  page: "/solution_blueprint",
  title: "Solution Blueprint",
});
ReactGA.send({
  hitType: "pageview",
  page: "/resource_form",
  title: "Resource Form",
});
ReactGA.send({
  hitType: "pageview",
  page: "/one_skill_everyday_April_2023",
  title: "One Skill Everyday April",
});
ReactGA.send({
  hitType: "pageview",
  page: "/one_skill_everyday_May_2023",
  title: "One Skill Everyday May",
});
ReactGA.send({
  hitType: "pageview",
  page: "/one_skill_everyday_June_2023",
  title: "One Skill Everyday June",
});
ReactGA.send({
  hitType: "pageview",
  page: "/one_skill_everyday_July_2023",
  title: "One Skill Everyday July",
});
ReactGA.send({
  hitType: "pageview",
  page: "/one_skill_everyday_August_2023",
  title: "One Skill Everyday August",
});
ReactGA.send({
  hitType: "pageview",
  page: "/one_skill_everyday_September_2023",
  title: "One Skill Everyday September",
});
ReactGA.send({
  hitType: "pageview",
  page: "/one_skill_everyday_October_2023",
  title: "One Skill Everyday October",
});
ReactGA.send({
  hitType: "pageview",
  page: "/nomination_for_interview_tech_skill_prep",
  title: "Nomination for Interview Tech Skill Prep",
});
ReactGA.send({
  hitType: "pageview",
  page: "/nomination_for_communication_prep",
  title: "Nomination for Communication Prep",
});
ReactGA.send({
  hitType: "pageview",
  page: "/nomination_for_de_prof_certification_prep",
  title: "Nomination for DE Prof Certification Prep",
});
ReactGA.send({
  hitType: "pageview",
  page: "/nomination_for_ml_prof_certification_prep",
  title: "Nomination for ML Prof Certification Prep",
});
ReactGA.send({
  hitType: "pageview",
  page: "/available-resource",
  title: "Available Resource",
});
ReactGA.send({
  hitType: "pageview",
  page: "/show-resource-form",
  title: "Show Resource Form Data Page",
});
ReactGA.send({
  hitType: "pageview",
  page: "/show-resource-form/:clientName",
  title: "Resource Form Details Page",
});

function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route
              path="/client_interview"
              exact
              element={<ClientInterview />}
            />
            <Route
              path="/certification_questions"
              exact
              element={<CertificationQuestions />}
            />
            <Route
              path="/solution_blueprint"
              exact
              element={<SolutionBlueprint />}
            />
            <Route path="/resource_form" exact element={<ResourceForm />} />
            <Route
              path="/one_skill_everyday_April_2023"
              exact
              element={<OneSkillEverydayApril />}
            />
            <Route
              path="/one_skill_everyday_May_2023"
              exact
              element={<OneSkillEverydayMay />}
            />
            <Route
              path="/one_skill_everyday_June_2023"
              exact
              element={<OneSkillEverydayJune />}
            />
            <Route
              path="/one_skill_everyday_July_2023"
              exact
              element={<OneSkillEverydayJuly />}
            />
            <Route
              path="/one_skill_everyday_August_2023"
              exact
              element={<OneSkillEverydayAugust />}
            />
            <Route
              path="/one_skill_everyday_September_2023"
              exact
              element={<OneSkillEverydaySept />}
            />
            <Route
              path="/one_skill_everyday_October_2023"
              exact
              element={<OneSkillEverydayOct />}
            />
            <Route
              path="/nomination_for_interview_tech_skill_prep"
              exact
              element={<NominationTechSkill />}
            />
            <Route
              path="/nomination_for_communication_prep"
              exact
              element={<NominationCommunication />}
            />
            <Route
              path="/nomination_for_de_prof_certification_prep"
              exact
              element={<NominationDE />}
            />
            <Route
              path="/nomination_for_ml_prof_certification_prep"
              exact
              element={<NominationMLCohort />}
            />
            <Route
              path="/available-resource"
              exact
              element={<AvailableResource />}
            />
            <Route
              path="/show-resource-form"
              exact
              element={<ShowResourceFormData />}
            />
            <Route
              path="/show-resource-form/:clientName"
              exact
              element={<ResourceFormDetails />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/client_interview" exact element={<Login />} />
          <Route path="/certification_questions" exact element={<Login />} />
          <Route path="/solution_blueprint" exact element={<Login />} />
          <Route path="/resource_form" exact element={<Login />} />
          <Route
            path="/one_skill_everyday_April_2023"
            exact
            element={<Login />}
          />
          <Route
            path="/one_skill_everyday_May_2023"
            exact
            element={<Login />}
          />
          <Route
            path="/one_skill_everyday_June_2023"
            exact
            element={<Login />}
          />
          <Route
            path="/one_skill_everyday_July_2023"
            exact
            element={<Login />}
          />
          <Route
            path="/one_skill_everyday_August_2023"
            exact
            element={<Login />}
          />
          <Route
            path="/one_skill_everyday_September_2023"
            exact
            element={<Login />}
          />
          <Route
            path="/one_skill_everyday_October_2023"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination_for_interview_tech_skill_prep"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination_for_communication_prep"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination_for_de_prof_certification_prep"
            exact
            element={<Login />}
          />
          <Route
            path="/nomination_for_ml_prof_certification_prep"
            exact
            element={<Login />}
          />
          <Route
            path="/show-resource-form/:client-name"
            exact
            element={<Login />}
          />
          <Route path="/available-resource" exact element={<Login />} />
          <Route path="/show-resource-form" exact element={<Login />} />
        </Routes>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
