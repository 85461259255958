export const DateAndTimeOct = {
  _03rd_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _04th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _05th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _06th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _09th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _10th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _11th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _12th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _13th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _16th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _17th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _18th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _19th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _20th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _23rd_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _24th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _25th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _26th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _27th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _30th_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _31st_October: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
};
