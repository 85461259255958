export const DateAndTimeJune = {
  _01st_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _02nd_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _03rd_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _05th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _06th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _07th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _08th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _09th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _10th_June: ["12:00 PM - 01:00 PM"],
  _12th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _13th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _14th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _15th_June: ["05:00 PM - 06:00 PM"],
  _16th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _17th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _19th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _20th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _21st_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _22nd_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _23rd_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _24th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _26th_June: ["12:00 PM - 01:00 PM"],
  _27th_June: ["05:00 PM - 06:00 PM"],
  _28th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _29th_June: ["12:00 PM - 01:00 PM"],
  _30th_June: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
};
