import ReactGA from "react-ga4";
import useUserDetailHook from "../utils/useUserDetailHook";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";
import styles from "../styles/pages.module.css";

const Main = () => {
  const [userEmailId, username] = useUserDetailHook();

  useEffect(() => {
    ReactGA.set({ user_id: username });
    document.title = "Centre of Excellence Landing Zone";
  }, [username]);

  return (
    <>
      <Navbar />
      <div>
        <div className="container">
          <div className="d-flex justify-content-center align-items-center mt-3">
            <div className={styles.keywords}>
              <p className={styles.key}>
                <strong>SKILLING </strong>=== Training an employee to do a
                particular Job
              </p>
              <p className={styles.key}>
                <strong>RESKILLING </strong>=== Skills learned to do a different
                Job
              </p>
              <p className={styles.key}>
                <strong>UPSKILLING </strong>=== Skills learned to improve
                current Job
              </p>
            </div>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center mt-3">
            <div>
              <p className={styles.upcoming_header}>
                Today ( 28th July 2023 , 05:00 PM - 06:00 PM )
              </p>
              <p className={styles.upcoming_body}>
                Modeling Data management solutions ( Ravi Sxena )
              </p>
            </div>
          </div> */}
          <div className="btn-container">
            <div className="left">
              <div className="button">
                <a
                  type="button"
                  className="btn btn-primary btn-lg"
                  href="https://gyan.celebaltech.com/client-questions-answers/client-1/index.html"
                  target="_blank"
                  rel="noreferrer"
                  id="gyan-client"
                >
                  Self-prepare me for client Interviews
                </a>
                <Tooltip
                  anchorId="gyan-client"
                  content="If you want to get prepared for client interviews, we have a good collection of past questions asked, recordings of mock interview, and topics to be prepared."
                />
              </div>
              <div className="button">
                <a
                  type="button"
                  className="btn btn-primary btn-lg"
                  href="https://academy.celebaltech.com/learn/home/Databricks-Certifications"
                  target="_blank"
                  rel="noreferrer"
                  id="databricks-cert"
                >
                  Self-prepare for Databricks certifications
                </a>
                <Tooltip
                  anchorId="databricks-cert"
                  content="If you want to prepare yourself for Databricks certifications, we have a structured course designed with many mock tests to gain confidence."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./resource_form"
                  id="resource-form"
                >
                  Find the Resource for your Project
                </Link>
                <Tooltip
                  anchorId="resource-form"
                  content="Hi Sales Team, If you require any resources for any of your clients, fill in this detailed form to give you the best resource."
                />
              </div>
              <div className="button">
                <a
                  type="button"
                  className="btn btn-primary btn-lg"
                  href="https://academy.celebaltech.com/learn/home/Databricks-Interns-Learning-Path"
                  target="_blank"
                  rel="noreferrer"
                  id="self-skill-databricks"
                >
                  Self-skill up on Databricks
                </a>
                <Tooltip
                  anchorId="self-skill-databricks"
                  content="We have a collection of self-paced courses with Day wise approach to help you become skilled in Databricks technology."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./one_skill_everyday_September_2023"
                  id="September-one-skill"
                >
                  September Nominations ( One Skill Everyday )
                </Link>
                <Tooltip
                  anchorId="September-one-skill"
                  content="Experts can nominate themselves to deliver one hour workshop on any topics related to project implementation in the past."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./nomination_for_interview_tech_skill_prep"
                  id="October-nomination-tech"
                >
                  October Nominations ( Tech Skill Cohort )
                </Link>
                <Tooltip
                  anchorId="October-nomination-tech"
                  content="Team leaders can nominate their team members for a 2-week Tech Skill Development on Databricks ( only for FTEs on the bench )"
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./nomination_for_de_prof_certification_prep"
                  id="October-nomination-de"
                >
                  October Nominations ( DE Certification Cohort )
                </Link>
                <Tooltip
                  anchorId="October-nomination-de"
                  content="Team leaders can nominate their team members for a month-long Data Engineering Certification Cohort."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./certification_questions"
                  id="certification-question"
                >
                  Share Certification Questions
                </Link>
                <Tooltip
                  anchorId="certification-question"
                  content="Team members who have recently appeared for Databricks certification, can share their questions here."
                />
              </div>
            </div>
            <div className="right">
              <div className="button">
                <a
                  type="button"
                  className="btn btn-primary btn-lg"
                  href="https://app.powerbi.com/links/xfRC3bsseo?ctid=e4e34038-ea1f-4882-b6e8-ccd776459ca0&pbi_source=linkShare"
                  target="_blank"
                  rel="noreferrer"
                  id="coe-dashboard"
                >
                  CoE Dashboard
                </a>
                <Tooltip
                  anchorId="coe-dashboard"
                  content="Team leaders can get their detailed analysis via this dashboard."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="/client_interview"
                  id="client-interview"
                >
                  Share Client Interview Questions
                </Link>
                <Tooltip
                  anchorId="client-interview"
                  content="Team members who have recently appeared for client interviews, can share their questions here."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./available-resource"
                  id="available-resource"
                >
                  Inform Us if Resources are Free from Project
                </Link>
                <Tooltip
                  anchorId="available-resource"
                  content="Team leaders can nominate their team members if their resources are available."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./solution_blueprint"
                  id="soln-blueprint"
                >
                  Share Solution Blueprint ( Case Study )
                </Link>
                <Tooltip
                  anchorId="soln-blueprint"
                  content="Team leaders can share case studies of recently executed projects in a pre-defined template."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./one_skill_everyday_October_2023"
                  id="October-one-skill"
                >
                  October Nominations ( One Skill Everyday )
                </Link>
                <Tooltip
                  anchorId="October-one-skill"
                  content="Experts can nominate themselves to deliver one hour workshop on any topics related to project implementation in the past."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./nomination_for_communication_prep"
                  id="October-nomination-communication"
                >
                  October Nominations ( Communication Cohort )
                </Link>
                <Tooltip
                  anchorId="October-nomination-communication"
                  content="Team leaders can nominate their team members for a 2-week Communication Cohort ( only for FTEs on the bench )"
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./nomination_for_ml_prof_certification_prep"
                  id="October-nomination-ml"
                >
                  October Nominations ( ML Certification Cohort )
                </Link>
                <Tooltip
                  anchorId="October-nomination-ml"
                  content="Team leaders can nominate their team members for a month-long Machine Learning Certification Cohort."
                />
              </div>
              <div className="button">
                <Link
                  type="button"
                  className="btn btn-primary btn-lg"
                  to="./show-resource-form"
                  id="resource-form-data"
                >
                  Show Resource form Data
                </Link>
                <Tooltip
                  anchorId="resource-form-data"
                  content="You can see the following requirements for active project."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Main;
