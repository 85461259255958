export const DateAndTimeMay = {
  _01st_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _02nd_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _03rd_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _05th_May: ["12:00 PM - 01:00 PM"],
  _06th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _08th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _09th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _10th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _11th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _12th_May: ["12:00 PM - 01:00 PM"],
  _13th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _15th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _16th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _18th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _19th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _20th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _22nd_May: ["12:00 PM - 01:00 PM"],
  _23rd_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _24th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _25th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _26th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _27th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _29th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _30th_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
  _31st_May: ["12:00 PM - 01:00 PM", "05:00 PM - 06:00 PM"],
};
