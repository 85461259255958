import React, { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { loginRequest } from "../../authConfig";
import { sendMail } from "../../utils/sendMail";
import useUserDetailHook from "../../utils/useUserDetailHook";
import styles from "../../styles/DetailsModal.module.css";

const ApplyModal = ({ modalOpen, setOpenModal, role }) => {
  const modalRef = useRef();

  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  const [name, setName] = useState("");
  const [HRMID, setHRMID] = useState("");
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenModal(false);
      }
    };

    if (modalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalOpen, setOpenModal]);

  useEffect(() => {
    setClientName(localStorage.getItem("ClientName"));
  }, []);

  const closeModal = () => {
    setOpenModal(false);
  };

  const apiCall = () => {
    const data = {
      ClientName: clientName,
      Role: role,
      Name: name,
      HRMID: HRMID,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/updateExcel`,
        { data: data, sheetName: "resource_nomination" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => console.log(error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = `Hello,\n
    Name: ${name} \n   
    HRMID: ${HRMID} `;

    let subject = `[${clientName} Resource Requirement form] Submission by - ${name}`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then(
          (response) => {
            if (
              response &&
              (response.status === 202 || response.status === 200)
            ) {
              alert("Data send Successfully!");
            }
          }
        );
      });

    apiCall();

    setName("");
    setHRMID("");
  };

  return (
    <div className={`${styles.modal} ${modalOpen ? styles.open : ""}`}>
      <div className={styles.modal_content} ref={modalRef}>
        <div className={styles.modal_header}>
          <h4>{`Nominate Yourself for ${role} role for ${clientName}`}</h4>
          <button className={styles.close_button} onClick={closeModal}>
            &times;
          </button>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.details_section}>
            <div className={styles.right_form}>
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.form_group}>
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className={styles.form_group}>
                  <label htmlFor="hrmid">HRM ID:</label>
                  <input
                    type="text"
                    id="hrmid"
                    name="hrmid"
                    value={HRMID}
                    onChange={(e) => setHRMID(e.target.value)}
                    required
                  />
                </div>
                <button type="submit">Apply</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyModal;
