import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { sendMail } from "../../utils/sendMail";
import { DateAndTimeOct } from "../../data/DateAndTimeOct";
import useUserDetailHook from "../../utils/useUserDetailHook";
import ReactGA from "react-ga4";
import axios from "axios";
import styles from "../../styles/pages.module.css";

const OneSkillEverydayOct = () => {
  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  useEffect(() => {
    ReactGA.set({ user_id: username });
    document.title = "One Skill Everyday October";
  }, [username]);

  const [name, setName] = useState("");
  const [HRMID, setHRMID] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [timeList, setTimeList] = useState([]);
  const [title, setTitle] = useState("");

  const datesArray = Object.keys(DateAndTimeOct).map((date) => {
    const day = date.slice(1, 3);
    const month = date.slice(3).replace("_", " ");
    return `${day}${month}`;
  });

  const dateHandler = (e) => {
    setDate(e.target.value);
    let newDate = "_" + e.target.value.replace(/\s/g, "_");
    setTimeList(DateAndTimeOct[newDate]);
    setTime("");
  };

  const timeHandler = (e) => {
    setTime(e.target.value);
  };

  const apiCall = () => {
    const data = {
      Name: name,
      HRMID: HRMID,
      Date: date,
      Time: time,
      Title: title,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/updateExcel`,
        {
          data: data,
          sheetName: "oct_oneskill_nominations",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      // .then((res) => console.log(res))
      .catch((error) => console.log(error));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const body = `Hello,\n\n
    Name: ${name} \n   
    HRMID: ${HRMID} \n
    Date: ${date} \n
    Time: ${time} \n
    Title: ${title} \n`;

    let subject = `[October Schedule] Submission by - ${name}`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then(
          (response) => {
            if (
              response &&
              (response.status === 202 || response.status === 200)
            ) {
              alert("Data send Successfully!");
            }
          }
        );
      });

    apiCall();

    setName("");
    setHRMID("");
    setDate("");
    setTime("");
    setTitle("");
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <h4>Submit your entry for October One Skill Everyday Sessions</h4>
        <form className="row" onSubmit={submitHandler}>
          <div className="col-6">
            <label htmlFor="name">Name</label>
            <input
              value={name}
              type="text"
              className="form-control"
              id="name"
              placeholder="Presenter Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-6">
            <label htmlFor="hrmid">HRM ID</label>
            <input
              onChange={(e) => setHRMID(e.target.value)}
              value={HRMID}
              type="text"
              className="form-control"
              id="HRMID"
              placeholder="HRM ID"
              required
            />
          </div>
          <div className="col-6">
            <label htmlFor="date">Date</label>
            <select
              name="date"
              id="date"
              className="form-control"
              value={date}
              required
              onChange={dateHandler}
            >
              <option selected disabled value="">
                Choose...
              </option>
              {datesArray.map((date, index) => {
                return (
                  <option value={date} key={index}>
                    {date}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-6">
            <label htmlFor="time">Time</label>
            <select
              name="time"
              id="time"
              className="form-control"
              value={time}
              required
              onChange={timeHandler}
            >
              <option selected disabled value="">
                Choose...
              </option>
              {timeList.map((time, index) => {
                return (
                  <option value={time} key={index}>
                    {time}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12">
            <label htmlFor="title">Title</label>
            <input
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              type="text"
              className="form-control"
              id="title"
              placeholder="Title of session"
              required
            />
          </div>
          <button
            type="submit"
            className={`btn btn-primary ${styles.submit_btn}`}
          >
            Submit
          </button>
        </form>
        <h4>October Calendar</h4>
        <table className={`${styles.table} ${styles.one_skill_table}`}>
          <thead>
            <tr>
              <th className={styles.date}>Date</th>
              <th className={styles.day}>Day</th>
              <th className={styles.time}>Time</th>
              <th className={styles.employee}>Presenter</th>
              <th>Title</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.sunday}>
              <td>01st October 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>02nd October 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>03rd October 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>04th October 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>05th October 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>06th October 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>07th October 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>08th October 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>09th October 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>10th October 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>11th October 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>12th October 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>13th October 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>14th October 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>15th October 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>16th October 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>17th October 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>18th October 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>19th October 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>20th October 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>21st October 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>22nd October 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>23rd October 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>24th October 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>25th October 2023</td>
              <td>Wednesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>26th October 2023</td>
              <td>Thursday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>27th October 2023</td>
              <td>Friday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>28th October 2023</td>
              <td>Saturday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.sunday}>
              <td>29th October 2023</td>
              <td>Sunday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr className={styles.grey}>
              <td>30th October 2023</td>
              <td>Monday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
            <tr>
              <td>31th October 2023</td>
              <td>Tuesday</td>
              <td></td>
              <td></td>
              <td className={styles.topic}></td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex justify-content-sm-between mt-4">
          <Link to="/" className={`btn btn-secondary ${styles.calendar_btn}`}>
            Back
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OneSkillEverydayOct;
