export const ProjectDomainOptions = [
  "BFSI",
  "Retail",
  "Manufacturing",
  "IT",
  "Healthcare",
  "Pharma",
  "Digital Native",
  "Energy",
  "Telecom",
  "Aviation",
  "Government",
  "Media",
  "Realty",
  "Logistics",
  "Education",
  "Advisory",
  "ITES",
  "Entertainment",
  "Insurance",
];

export const RegionOptions = [
  "North America",
  "NATAM",
  "LATAM",
  "EMEA",
  "APAC",
];

export const ProjectTypeOptions = [
  "POC",
  "Pilot",
  "Project",
  "Project Support",
];

export const EngagementTypeOptions = [
  "T&M",
  "Fixed Bid",
  "Free of cost",
  "Microsoft Sponsored",
  "Databricks Sponsored",
  "In-House CT Project",
];

export const RolesOption = [
  "Databricks Engineer",
  "Power BI Developer",
  "Solution Architect",
  "DevOps Engineer",
  "DevOps Architect",
  "Data Scientist",
  "ML Engineer",
  "ML Architect",
  "Infra Engineer",
  "Infra Architect",
  "Technical PM + Integration Lead",
  "Tech Lead - IM",
  "Senior UX/UI Designer",
  "Junior UX/UI Designer",
  "Senior Frontend Developer",
  "Junior Frontend Developer",
  "Senior Backend Developer",
  "Junior Backend Developer",
  "Senior Azure Infra/Security Engineer",
  "Senior Devops Engineer",
  "Senior Data Engineer",
  "Senior Unity Developer",
  "Junior Unity Developer",
  "Senior 3D Artist/Designer",
  "Senior QA",
  "QA",
  "Delievery Manager",
  "Scrum Master",
  "Language Interpretor/Translator",
  "PMO Support + Language Support",
  "ML/CV Tech Lead",
  "ML/NLP Tech Lead",
  "ML/NLP Engineer",
  "Data Engineer",
  "ML/CV Engineer",
  "Buisness Analyst",
  "Network Engineer",
  "Other",
];

export const RoleRegionOptions = ["NATAM", "LATAM", "EMEA", "APAC"];

export const officeLocationOptions = [
  "Jaipur ( Mansarovar )",
  "Jaipur ( Malviya Nagar )",
  "Jaipur ( Jhalana )",
  "Noida",
  "Pune",
];

export const employmenttypeOptions = ["FTE", "Contarctor", "Trainee", "Intern"];

export const shifttimeOptions = [
  "09 AM - 06 PM IST",
  "10 AM - 07 PM IST",
  "03 PM - 12 AM IST",
  "01 PM - 10 PM IST",
  "02 PM - 11 PM IST",
];
