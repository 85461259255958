import MultipleNominations from "../../components/Form/MultipleNominations";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

const AvailableResource = () => {
  return (
    <>
      <Navbar />
      <MultipleNominations
        title="Available Resource"
        SheetName="available_resource"
      />
      <Footer />
    </>
  );
};

export default AvailableResource;
