import React, { useEffect, useState } from "react";
import useUserDetailHook from "../../utils/useUserDetailHook";
import { Link, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import axios from "axios";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import ResourceData from "./ResourceData";
import RolesData from "./RolesData";

const ResourceFormDetails = ({ data }) => {
  const [userEmailId, username] = useUserDetailHook();
  const [apiData, setApiData] = useState([]);
  const [clientData, setClientData] = useState([]);

  const { clientName } = useParams();

  useEffect(() => {
    ReactGA.set({ user_id: username });
    document.title = "Resource Form Details Page";
  }, [username]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/getresourceformdata`)
      .then((res) => setApiData(res.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setClientData(filterByClientName(clientName));
  }, [apiData]);

  const filterByClientName = (clientName) => {
    return apiData.filter((entry) => entry.resource.ClientName === clientName);
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <h3>{`${clientName} Requirement Details`}</h3>
        {clientData.length === 0 ? (
          <p>No data present</p>
        ) : (
          <>
            <div>
              <ResourceData clientData={clientData} />
            </div>
            <div>
              <h3>Roles</h3>
              {clientData[0].roles.map((role, index) => (
                <div key={index}>
                  <h6 style={{ color: "blue", marginLeft: "2rem" }}>
                    Role - {index + 1}
                  </h6>
                  <RolesData data={role} />
                  <hr />
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-sm-between mt-4 mb-3">
              <Link to="/show-resource-form" className="btn btn-secondary">
                Back
              </Link>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ResourceFormDetails;
