import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import useUserDetailHook from "../../utils/useUserDetailHook";
import ReactGA from "react-ga4";
import styles from "../../styles/pages.module.css";

const OneSkillEverydayApril = () => {
    const [userEmailId, username] = useUserDetailHook();

    useEffect(() => {
        ReactGA.set({ user_id: username });
        document.title = "One Skill Everyday April"
    }, [username]);

    return (
        <>
            <Navbar />
            <div className='container'>
                <p className='mt-3'>
                    Microsoft Teams meeting, Join on your computer, mobile app or room device. <br />
                    <a href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZTljMmJhMGQtM2U4OC00YzEwLTk3ZjEtMzkzMzZmNmFiMzgw%40thread.v2/0?context=%7b%22Tid%22%3a%22e4e34038-ea1f-4882-b6e8-ccd776459ca0%22%2c%22Oid%22%3a%2255dad4b7-7765-4d73-a8e1-398523f62ed8%22%7d" target="_blank" rel="noreferrer" className='link'>Click here to join the meeting</a><br />
                    Meeting ID: 440 068 691 935<br />
                    Passcode: 3UmGbm
                </p>
                <h4>March and April Calendar</h4>
                <table className={`${styles.table} ${styles.one_skill_table}`}>
                    <thead>
                        <tr>
                            <th className={styles.date}>Date</th>
                            <th className={styles.day}>Day</th>
                            <th className={styles.time}>Time</th>
                            <th className={styles.employee}>Presenter</th>
                            <th>Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>30th March 2023</td>
                            <td>Thursday</td>
                            <td>05:00 PM - 06:00 PM</td>
                            <td>Chandan Jangid</td>
                            <td className={styles.topic}>CI/CD Implementation using Databricks on AWS</td>
                        </tr>
                        <tr className={styles.sunday}>
                            <td>02nd April 2023</td>
                            <td>Sunday</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className={styles.grey}>
                            <td><s>03rd April 2023</s></td>
                            <td><s>Monday</s></td>
                            <td><s>05:00 PM - 06:00 PM</s></td>
                            <td><s>Risbhabh Ruwatia</s></td>
                            <td className={styles.topic}><s>Extracting Data from Different Sources ( Salesforce, SAP BW, OData )</s></td>
                        </tr>
                        <tr>
                            <td>04th April 2023</td>
                            <td>Tuesday</td>
                            <td>05:00 PM - 06:00 PM</td>
                            <td>Pranjal Gupta</td>
                            <td className={styles.topic}>Assessment Strategy and how to create future based architecture</td>
                        </tr>
                        <tr className={styles.grey}>
                            <td>05th April 2023</td>
                            <td>Wednesday</td>
                            <td>12:00 PM - 01:00 PM</td>
                            <td>Prarbdh Ranjan</td>
                            <td className={styles.topic}>Data Mastering using Graph Frames</td>
                        </tr>
                        <tr>
                            <td>06th April 2023</td>
                            <td>Thursday</td>
                            <td>05:00 PM - 06:00 PM</td>
                            <td>Pradeep Kumar</td>
                            <td className={styles.topic}>Best Practices For Data Modeling & Data Migration (DMA) For On-premises to Cloud using Synapse EDW</td>
                        </tr>
                        <tr className={styles.grey}>
                            <td><s>07th April 2023</s></td>
                            <td><s>Friday</s></td>
                            <td><s>04:00 PM - 05:00 PM</s></td>
                            <td><s>Hozefa Mustan Kanchwala</s></td>
                            <td className={styles.topic}><s>Monitoring and Troubleshooting Jobs using Ganglia & Spark UI & Extension to Grafana</s></td>
                        </tr>
                        <tr>
                            <td><s>08th April 2023</s></td>
                            <td><s>Saturday</s></td>
                            <td><s>04:00 PM - 05:00 PM</s></td>
                            <td><s>Shubham Choubey</s></td>
                            <td className={styles.topic}><s>Optimize Cost & Performance by Migrating Pentaho Jobs to Databricks Lakehouse Platform</s></td>
                        </tr>
                        <tr className={styles.sunday}>
                            <td>09th April 2023</td>
                            <td>Sunday</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className={styles.grey}>
                            <td><s>10th April 2023</s></td>
                            <td><s>Monday</s></td>
                            <td><s>05:00 PM - 06:00 PM</s></td>
                            <td><s>Ansari Fatima</s></td>
                            <td className={styles.topic}><s>Optimizing Data Integration using Autoloader and Copy Into of databricks</s></td>
                        </tr>
                        <tr>
                            <td>11th April 2023</td>
                            <td>Tuesday</td>
                            <td>12:00 PM - 01:00 PM</td>
                            <td>Hozefa Mustan Kanchwala</td>
                            <td className={styles.topic}>Monitoring and Troubleshooting Jobs using Ganglia & Spark UI & Extension to Grafana</td>
                        </tr>
                        <tr className={styles.grey}>
                            <td><s>12th April 2023</s></td>
                            <td><s>Wednesday</s></td>
                            <td><s>03:00 PM - 04:00 PM</s></td>
                            <td><s>Ayush Mangal</s></td>
                            <td className={styles.topic}><s>Data ingestion, data quality enforcement and task orchestration using Metadata driven DLT concepts.</s></td>
                        </tr>
                        <tr>
                            <td>13th April 2023</td>
                            <td>Thursday</td>
                            <td>05:00 PM - 06:00 PM</td>
                            <td>Kratik Mehta</td>
                            <td className={styles.topic}>Incrementally and efficiently process new data files as they arrive in cloud storage without any additional setup</td>
                        </tr>
                        <tr className={styles.grey}>
                            <td><s>14th April 2023</s></td>
                            <td><s>Friday</s></td>
                            <td><s>05:00 PM - 06:00 PM</s></td>
                            <td><s>Nikhil Garg</s></td>
                            <td className={styles.topic}><s>How to incrementally copy data from source system to sink with date hierarchy to store the data into ADLS with all the challenges</s></td>
                        </tr>
                        <tr>
                            <td><s>15th April 2023</s></td>
                            <td><s>Saturday</s></td>
                            <td><s>11:00 AM - 01:00 PM</s></td>
                            <td><s>Rishabh Pandey</s></td>
                            <td className={styles.topic}><s>Oracle databricks integration using DLT and orchestration using ADF</s></td>
                        </tr>
                        <tr className={styles.sunday}>
                            <td>16th April 2023</td>
                            <td>Sunday</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className={styles.grey}>
                            <td>17th April 2023</td>
                            <td>Monday</td>
                            <td>12:00 PM - 01:00PM</td>
                            <td>Abhishek Jarwal </td>
                            <td className={styles.topic}>How to design Dynamic Data Validation Script for Data Sign Off and Productionizing the Code</td>
                        </tr>
                        <tr>
                            <td><s>18th April 2023</s></td>
                            <td><s>Tuesday</s></td>
                            <td><s>12:00 PM - 01:00 PM</s></td>
                            <td><s>Prateek Gupta</s></td>
                            <td className={styles.topic}><s>Processing Realtime data on ADLS using Autoloader and DLT pipelines to create Power BI reports with minimum latency</s></td>
                        </tr>
                        <tr className={styles.grey}>
                            <td><s>19th April 2023</s></td>
                            <td><s>Wednesday</s></td>
                            <td><s>02:00 PM - 03:00 PM</s></td>
                            <td><s>Kalpit Dhaked</s></td>
                            <td className={styles.topic}><s>Strategy enabling multiple refreshes/extraction in a day from SAP into the Lakehouse</s></td>
                        </tr>
                        <tr>
                            <td><s>20th April 2023</s></td>
                            <td><s>Thursday</s></td>
                            <td><s>05:00 PM - 06:00 PM</s></td>
                            <td><s>Divakar Sharma</s></td>
                            <td className={styles.topic}><s>Data Migration Jobs and Data Processing Jobs from Talend to Azure Databricks using ADF</s></td>
                        </tr>
                        <tr className={styles.grey}>
                            <td><s>21st April 2023</s></td>
                            <td><s>Friday</s></td>
                            <td><s>12:00 PM - 01:00 PM</s></td>
                            <td><s>Ayush Mangal</s></td>
                            <td className={styles.topic}><s>Data ingestion, data quality enforcement and task orchestration using Metadata driven DLT concepts</s></td>
                        </tr>
                        <tr>
                            <td>22nd April 2023</td>
                            <td>Saturay</td>
                            <td>05:00 PM - 06:00 PM</td>
                            <td>Rishabh Pandey</td>
                            <td className={styles.topic}>Oracle databricks integration using DLT and orchestration using ADF</td>
                        </tr>
                        <tr className={styles.sunday}>
                            <td>23rd April 2023</td>
                            <td>Sunday</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className={styles.grey}>
                            <td>24th April 2023</td>
                            <td>Monday</td>
                            <td>12:00 PM - 01:00 PM <hr /> 05:00 PM - 06:00 PM </td>
                            <td>Ansari Fatima <hr /> Chayan</td>
                            <td className={styles.topic}>Migrating SAP HANA Views to Databricks while implementing modernization <hr /> Databricks Cost Optimisation and  their Internal Features</td>
                        </tr>
                        <tr>
                            <td>25th April 2023</td>
                            <td>Tuesday</td>
                            <td><s>12:00 PM - 01:00 PM</s> <hr /> 05:00 PM - 06:00 PM</td>
                            <td><s>Akash Tyagi</s> <hr /> Amit Kumawat</td>
                            <td className={styles.topic}><s>Overcome the limitation of data ingestion using Apache Nifi ( ETL Tool )</s> <hr /> SQL Optimisation and Tuning, Dynamic SQL and Procedures</td>
                        </tr>
                        <tr className={styles.grey}>
                            <td>26th April 2023</td>
                            <td>Wednesday</td>
                            <td><s>05:00 PM - 06:00 PM <hr style={{ marginTop: "2.6rem" }} /> 05:00 PM - 06:00 PM</s></td>
                            <td><s>Prateek Gupta <hr style={{ marginTop: "2.6rem" }} /> Kashetti Prashanth</s></td>
                            <td className={styles.topic}><s>Processing Realtime data on ADLS using Autoloader and DLT pipelines to create Power BI reports with minimum latency <hr />MLOps Hands-On on Azure Databricks Platform</s></td>
                        </tr>
                        <tr>
                            <td>27th April 2023</td>
                            <td>Thursday</td>
                            <td>05:00 PM - 06:00 PM <hr /> <s>05:00 PM - 06:00 PM</s></td>
                            <td>Divakar Sharma <hr /> <s>Chetan Mali</s></td>
                            <td className={styles.topic}>Data Migration Jobs and Data Processing Jobs from Talend to Azure Databricks using ADF <hr /> <s>Regular Expression And Coding Best Practices</s></td>
                        </tr>
                        <tr className={styles.grey}>
                            <td>28th April 2023</td>
                            <td>Friday</td>
                            <td><s>12:00 PM - 01:00 PM</s> <hr /> 05:00 PM - 06:00 PM</td>
                            <td><s>Kalpit Dhaked</s> <hr /> Akshat Vijayvergia</td>
                            <td className={styles.topic}><s>Strategy enabling multiple refreshes/extraction in a day from SAP into the Lakehouse</s> <hr /> How to map the Client Requirenment into actionable items</td>
                        </tr>
                        <tr>
                            <td>29th April 2023</td>
                            <td>Saturday</td>
                            <td>11:00 AM - 01:00 PM <hr style={{ marginTop: "2.6rem" }} /> <s>05:00 PM - 06:00 PM</s></td>
                            <td>Pankaj Kumar <hr style={{ marginTop: "2.6rem" }} /> <s>Nikhil Garg</s></td>
                            <td className={styles.topic}>SCD (Slowly Changing Dimensions) by SSIS to maintain the history & CDC (Change Data Capture) by SSIS to incremental data integration <hr /> <s>How to incrementally copy data from source system to sink with date hierarchy to store the data into ADLS with all the challenges</s></td>
                        </tr>
                        <tr className={styles.sunday}>
                            <td>30th April 2023</td>
                            <td>Sunday</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table >
                <div className="d-flex justify-content-sm-between mt-4">
                    <Link to="/" className={`btn btn-secondary ${styles.calendar_btn}`}>
                        Back
                    </Link>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default OneSkillEverydayApril;