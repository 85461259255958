import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useUserDetailHook from "../../utils/useUserDetailHook";
import { sendMail } from "../../utils/sendMail";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import axios from "axios";

const MultipleNominations = ({ title, SheetName }) => {
  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  useEffect(() => {
    document.title = title;
  }, []);

  const [roles, setRoles] = useState([
    {
      name: "",
      HRMID: "",
    },
  ]);

  const addRole = () => {
    setRoles([
      ...roles,
      {
        name: "",
        HRMID: "",
      },
    ]);
  };

  const handleChangeRoles = (e, index) => {
    var newRoles = [...roles];
    newRoles[index][e.target.name] = e.target.value;
    setRoles(newRoles);
  };

  const removeRole = (e, index) => {
    let newRoles = [...roles];
    newRoles.splice(index, 1);
    setRoles(newRoles);
  };

  const apiCall = () => {
    const data = roles.map((role) => {
      const newData = {
        Name: role.name,
        HRMID: role.HRMID,
      };
      return newData;
    });

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/updateExcel`,
        {
          data: data,
          sheetName: `${SheetName}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => console.log(error));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    var body = `Hello,\n\n`;

    roles.forEach((role) => {
      body = body + `Name: ${role.name}\nHRM ID: ${role.HRMID}\n\n`;
    });

    let subject = `[${title}] Submission by - ${username}`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then(
          (response) => {
            if (
              response &&
              (response.status === 202 || response.status === 200)
            ) {
              alert("Form data send Successfully!");
            }
          }
        );
      });

    apiCall();

    setRoles([
      {
        name: "",
        HRMID: "",
      },
    ]);
  };

  return (
    <div className="container">
      <h3 style={{ marginBottom: "1rem" }}>{title}</h3>
      <form
        className="form"
        style={{ width: "60rem" }}
        onSubmit={submitHandler}
      >
        {roles.map((role, index) => {
          return (
            <div key={index}>
              <div className="col-12">
                <label htmlFor="name">Name</label>
                <input
                  name="name"
                  onChange={(e) => handleChangeRoles(e, index)}
                  value={role.name}
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="col-12">
                <label htmlFor="hrmid">HRM ID</label>
                <input
                  name="HRMID"
                  onChange={(e) => handleChangeRoles(e, index)}
                  value={role.HRMID}
                  type="text"
                  className="form-control"
                  id="HRMID"
                  placeholder="HRM ID"
                  required
                />
              </div>
              {roles.length > 1 && (
                <div className="d-flex justify-content-end">
                  <div
                    className="btn btn-primary"
                    style={{ width: "33%", marginBottom: "2rem" }}
                    id="add-form-btn"
                    onClick={(e) => removeRole(e, index)}
                  >
                    {title === "Available Resource"
                      ? "Remove Resource"
                      : "Remove Nomination"}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="btn btn-success" id="add-form-btn" onClick={addRole}>
          {title === "Available Resource"
            ? "Add Another Resource"
            : "Add Another Nomination"}
        </div>
        <div className="d-flex justify-content-sm-between mt-4">
          <Link
            to="/"
            className="btn btn-secondary"
            style={{ width: "25% !important" }}
          >
            Back
          </Link>
          <button
            type="submit"
            className="btn btn-primary add-form-btn"
            style={{ width: "25% !important" }}
          >
            {roles.length > 1 ? "Submit All" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MultipleNominations;
