import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import celebal_logo from "../../images/celebal_logo_new.png";
import login_page from "../../images/login_page.png";
import styles from "./LoginPage.module.css";

const LoginPage = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6">
          <div className={styles.img_container}>
            <img className={styles.img} src={celebal_logo} alt="CT Logo" />
          </div>
          <div className={styles.text_container}>
            <h4 className={styles.title}>Hi, Welcome!</h4>
            <p className={styles.subtitle}>
              <strong>Center of Excellence ( Databricks ) Landing Zone </strong>
            </p>
            <button className={styles.ms_btn} onClick={handleLogin}>
              <span className={styles.ms_logo}></span>{" "}
              <p className={styles.btn_text}>Sign in with Microsoft Account</p>
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className={styles.right_img}>
            <img src={login_page} alt="" width="75%" height="75%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
