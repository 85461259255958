import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import ReactGA from "react-ga4";
import axios from "axios";

import { loginRequest } from "../../authConfig";
import { sendMail } from "../../utils/sendMail";
import { CountryCity } from "../../data/CountryCity";
import { USAStates } from "../../data/USAStates";
import { CanadaStates } from "../../data/CanadaStates";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import useUserDetailHook from "../../utils/useUserDetailHook";
import {
  ProjectDomainOptions,
  RegionOptions,
  ProjectTypeOptions,
  EngagementTypeOptions,
  RolesOption,
  RoleRegionOptions,
  officeLocationOptions,
  employmenttypeOptions,
  shifttimeOptions,
} from "../../components/ResourceForm/selectOptions";
import img1 from "../../images/Databricks_resource_allocation.jpg";
import styles from "../../styles/pages.module.css";

const ResourceForm = () => {
  const { instance, accounts } = useMsal();
  const [userEmailId, username] = useUserDetailHook();

  useEffect(() => {
    ReactGA.set({ user_id: username });
    document.title = "Resource for your project form";
  }, [username]);

  const [HRMID, setHRMID] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectname, setProjectname] = useState("");
  const [projectDomain, setProjectDomain] = useState("");
  const [existingProject, setExistingProject] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [state, setState] = useState("");
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState("");
  const [crm, setCrm] = useState("");
  const [zohoprojid, setZohoprojid] = useState("");
  const [clientPartner, setClientPartner] = useState("");
  const [accountoperations, setAccountoperations] = useState("");
  const [delieverypartner, setDelieverypartner] = useState("");
  const [comptencypartner, setComptencypartner] = useState("");
  const [projecttype, setProjecttype] = useState("");
  const [engagementtype, setEngagementtype] = useState("");
  const [buisness, setBuisness] = useState("");
  const [technicaUseCase, setTechnicaUseCase] = useState("");
  const [architectureDoc, setArchitectureDoc] = useState("");
  const [proposedtechnical, setProposedtechnical] = useState("");
  const [proposedtechnicalsolution, setProposedtechnicalsolution] =
    useState("");
  const [remarks, setRemarks] = useState("");

  const countryChangeHandler = (e) => {
    setCountry(e.target.value);
    setCityList(CountryCity[e.target.value]);
    setCity("");
  };

  const countryChangeHandler2 = (e) => {
    setCountry(e.target.value);
    if (e.target.value === "USA") {
      setStatesList(USAStates);
    } else {
      setStatesList(CanadaStates);
    }
    setState("");
  };

  const cityChangeHandler = (e) => {
    setCity(e.target.value);
  };

  const stateChangeHandler = (e) => {
    setState(e.target.value);
  };

  /*--------------Role------------*/
  const [newCityList, setNewCityList] = useState([]);

  const [roles, setRoles] = useState([
    {
      role: "",
      otherRoles: "",
      techSkills: "",
      mode: "",
      workExperience: "",
      ctWorkExperience: "",
      headCount: "",
      workLocation: "",
      officeLocation: "",
      region: "",
      country: "",
      city: "",
      preferredEmploymentType: "",
      ShiftTime: "",
      OverlapRequired: "",
      InterviewMandatory: "",
      BudgetRange: "",
      MappingTimePeriod: "",
      Utilization: "",
      MinimumEngagementPeriod: "",
      MaxEngagementPeriod: "",
      EngagementTentativeStartDate: "",
      EngagementTentativeEndDate: "",
      CertificationRequirenment: "",
      ResourceInTypicalDay: "",
      TechnicalSkills: "",
      BehaviouralSkills: "",
    },
  ]);

  const addRole = () => {
    setRoles([
      ...roles,
      {
        role: "",
        techSkills: "",
        mode: "",
        workExperience: "",
        ctWorkExperience: "",
        headCount: "",
        workLocation: "",
        officeLocation: "",
        region: "",
        country: "",
        city: "",
        preferredEmploymentType: "",
        ShiftTime: "",
        OverlapRequired: "",
        InterviewMandatory: "",
        BudgetRange: "",
        MappingTimePeriod: "",
        Utilization: "",
        MinimumEngagementPeriod: "",
        MaxEngagementPeriod: "",
        EngagementTentativeStartDate: "",
        EngagementTentativeEndDate: "",
        CertificationRequirenment: "",
        ResourceInTypicalDay: "",
        TechnicalSkills: "",
        BehaviouralSkills: "",
      },
    ]);
  };

  const ClearForm = () => {
    setHRMID("");
    setClientName("");
    setProjectname("");
    setProjectDomain("");
    setExistingProject("");
    setRegion("");
    setCountry("");
    setStatesList(USAStates);
    setCityList([]);
    setCity("");
    setCrm("");
    setZohoprojid("");
    setClientPartner("");
    setAccountoperations("");
    setDelieverypartner("");
    setComptencypartner("");
    setProjecttype("");
    setEngagementtype("");
    setTechnicaUseCase("");
    setBuisness("");
    setArchitectureDoc("");
    setProposedtechnical("");
    setProposedtechnicalsolution("");
    setRemarks("");
    setRoles([
      {
        role: "",
        techSkills: "",
        mode: "",
        workExperience: "",
        ctWorkExperience: "",
        headCount: "",
        workLocation: "",
        officeLocation: "",
        region: "",
        country: "",
        city: "",
        preferredEmploymentType: "",
        ShiftTime: "",
        OverlapRequired: "",
        InterviewMandatory: "",
        BudgetRange: "",
        MappingTimePeriod: "",
        Utilization: "",
        MinimumEngagementPeriod: "",
        MaxEngagementPeriod: "",
        EngagementTentativeStartDate: "",
        EngagementTentativeEndDate: "",
        CertificationRequirenment: "",
        ResourceInTypicalDay: "",
        TechnicalSkills: "",
        BehaviouralSkills: "",
      },
    ]);
  };

  const countryChangeHandler1 = (e, index) => {
    var newRoles = [...roles];
    newRoles[index][e.target.name] = e.target.value;
    setNewCityList(CountryCity[e.target.value]);
    newRoles[index][city] = "";
    setRoles(newRoles);
  };

  const cityChangeHandler1 = (e, index) => {
    var newRoles = [...roles];
    newRoles[index][e.target.name] = e.target.value;
    setRoles(newRoles);
  };

  const removeRole = (e, index) => {
    let newRoles = [...roles];
    newRoles.splice(index, 1);
    setRoles(newRoles);
  };

  const handleChangeRoles = (e, index) => {
    var newRoles = [...roles];
    newRoles[index][e.target.name] = e.target.value;
    setRoles(newRoles);
  };

  /*--------------Role End------------*/

  /*--------------Api call-------------*/
  const apiCall = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/addresourceformdata`,
        { data: data },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => console.log(error));
  };

  /*--------------On Submit------------*/

  const submitHandler = (e) => {
    const resoure_form = document.getElementById("resoureform");

    e.preventDefault();

    const obj1 = {
      "Name: ": username,
      "HRM ID: ": HRMID,
      "Client Name: ": clientName,
      "Project Name: ": projectname,
      "Project Domain: ": projectDomain,
      "Existing Project: ": existingProject,
      "Region: ": region,
      "Country: ": country,
      "State: ": state,
      "City: ": city,
      "CRM ID: ": crm,
      "Zoho Project ID: ": zohoprojid,
      "Client Partner (Account Manager): ": clientPartner,
      "Account operations (Sales Representative): ": accountoperations,
      "Delivery Partner (Tech Lead): ": delieverypartner,
      "Competency Partner (Presales Solution Architect): ": comptencypartner,
      "Project Type: ": projecttype,
      "Engagement Type: ": engagementtype,
      "Business Use Case Description: ": buisness,
      "Technical Use Case: ": technicaUseCase,
      "Architecture Document Upload: ": architectureDoc,
      "Proposed Techical Solution Description: ": proposedtechnical,
      "Proposed Techical Solution Document Upload: ": proposedtechnicalsolution,
      "Remarks: ": remarks,
    };

    var body = `Hello,\n\n`;

    for (const property in obj1) {
      if (property === "Region: ") {
        if (obj1[property] === "North America") {
          body += property + obj1[property] + "\n";
          body += "Country: " + obj1["Country: "] + "\n";
          body += "State: " + obj1["State: "] + "\n";
          continue;
        } else {
          body += property + obj1[property] + "\n";
          body += "Country: " + obj1["Country: "] + "\n";
          body += "City: " + obj1["City: "] + "\n";
          continue;
        }
      } else if (property === "Country: ") {
        continue;
      } else if (property === "City: ") {
        continue;
      } else if (property === "State: ") {
        continue;
      } else {
        body += property + obj1[property] + "\n";
      }
    }

    body = body + `\nRoles :\n`;

    roles.forEach((role, index) => {
      body = body + `\nRole - ${index + 1}:\nRole: ${role.role}\n`;

      if (role.role === "Other") {
        body = body + `Other Roles: ${role.otherRoles}\n`;
      }

      body =
        body +
        `Tech Skills Summary: ${role.techSkills}\nMode: ${role.mode}\nWork Experience: ${role.workExperience}\nCT Work Experience: ${role.ctWorkExperience}\nHead Count: ${role.headCount}\nWork Location: ${role.workLocation}\n`;

      if (role.workLocation === "Onsite") {
        body =
          body +
          `Region: ${role.region}\nCountry: ${role.country}\nCity: ${role.city}\n`;
      } else {
        body = body + `Office Location: ${role.officeLocation}\n`;
      }

      body =
        body +
        `Preferred Employment Type: ${role.preferredEmploymentType}\nShift Time: ${role.ShiftTime}\nOverlap Required: ${role.OverlapRequired}\nInterview Mandatory (Client / Partner): ${role.InterviewMandatory}\nBudget Range ( CTC per month/ per hour ): ${role.BudgetRange}\nMapping Time Period: ${role.MappingTimePeriod}\nUtilization (as per man hours per day): ${role.Utilization}\nMinimum Engagement Period (anticipated): ${role.MinimumEngagementPeriod}\nMax Engagement Period (anticipated): ${role.MaxEngagementPeriod}\nEngagement Tentative Start Date: ${role.EngagementTentativeStartDate}\nEngagement Tentative End Date: ${role.EngagementTentativeEndDate}\nCertification Requirenment: ${role.CertificationRequirenment}\nResource could be doing in a Typical Day: ${role.ResourceInTypicalDay}\nTechnical Skills: ${role.TechnicalSkills}\nBehavioural Skills: ${role.BehaviouralSkills}\n
        `;
    });

    let subject = `[Resource for your Project] Form Submission by - ${username}`;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendMail(subject, body, response.accessToken, userEmailId).then(
          (response) => {
            if (
              response &&
              (response.status === 202 || response.status === 200)
            ) {
              alert("Data send Successfully!");
            }
          }
        );
      });

    const submissionData = {
      Name: username,
      HRMID: HRMID,
      ClientName: clientName,
      ProjectName: projectname,
      ProjectDomain: projectDomain,
      ExistingProject: existingProject,
      Region: region,
      Country: country,
      State: state,
      City: city,
      CRMID: crm,
      ZohoProjectID: zohoprojid,
      ClientPartner: clientPartner,
      AccountOperations: accountoperations,
      DelieveryPartner: delieverypartner,
      ComptencyPartner: comptencypartner,
      ProjectType: projecttype,
      EngagementType: engagementtype,
      BuisnessUseCase: buisness,
      TechnicaUseCase: technicaUseCase,
      ArchitectureDoc: architectureDoc,
      ProposedTechnical: proposedtechnical,
      ProposedTechnicalSolution: proposedtechnicalsolution,
      Remarks: remarks,
      Roles: roles.map((role, index) => {
        const roleData = { Role: role.role };

        if (role.role === "Other") {
          roleData.otherRoles = role.otherRoles;
        }

        roleData.TechSkills = role.techSkills;
        roleData.Mode = role.mode;
        roleData.WorkExperience = role.workExperience;
        roleData.CTWorkExperience = role.ctWorkExperience;
        roleData.HeadCount = role.headCount;
        roleData.WorkLocation = role.workLocation;

        if (role.workLocation === "Onsite") {
          roleData.Region = role.region;
          roleData.Country = role.country;
          roleData.City = role.city;
        } else {
          roleData.OfficeLocation = role.officeLocation;
        }

        roleData.PreferredEmploymentType = role.preferredEmploymentType;
        roleData.ShiftTime = role.ShiftTime;
        roleData.OverlapRequired = role.OverlapRequired;
        roleData.InterviewMandatory = role.InterviewMandatory;
        roleData.BudgetRange = role.BudgetRange;
        roleData.MappingTimePeriod = role.MappingTimePeriod;
        roleData.Utilization = role.Utilization;
        roleData.MinimumEngagementPeriod = role.MinimumEngagementPeriod;
        roleData.MaxEngagementPeriod = role.MaxEngagementPeriod;
        roleData.EngagementTentativeStartDate =
          role.EngagementTentativeStartDate;
        roleData.EngagementTentativeEndDate = role.EngagementTentativeEndDate;
        roleData.CertificationRequirenment = role.CertificationRequirenment;
        roleData.ResourceInTypicalDay = role.ResourceInTypicalDay;
        roleData.TechnicalSkills = role.TechnicalSkills;
        roleData.BehaviouralSkills = role.BehaviouralSkills;

        return roleData;
      }),
    };

    apiCall(submissionData);

    resoure_form.reset();
    ClearForm();
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <a href={img1} target="_blank" className={styles.img_container}>
          <img src={img1} alt="Workflow Image" className={styles.image} />
        </a>
        <h4>Resource for your project form</h4>
        <form id="resoureform" className="row" onSubmit={submitHandler}>
          <>
            <div className="col-4">
              <label htmlFor="name">Name</label>
              <input
                value={username}
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                required
                disabled
              />
            </div>
            <div className="col-4">
              <label htmlFor="hrmid">HRM ID</label>
              <input
                onChange={(e) => setHRMID(e.target.value)}
                value={HRMID}
                type="text"
                className="form-control"
                id="HRMID"
                placeholder="HRM ID"
                required
              />
            </div>
            <div className="col-4">
              <label htmlFor="clientname">Client Name</label>
              <input
                onChange={(e) => setClientName(e.target.value)}
                value={clientName}
                type="text"
                className="form-control"
                id="clientname"
                placeholder="Client name"
                required
              />
            </div>
            <div className="col-4">
              <label htmlFor="projectname">Project Name</label>
              <input
                onChange={(e) => setProjectname(e.target.value)}
                value={projectname}
                type="text"
                className="form-control"
                id="projectname"
                placeholder="Project name"
                required
              />
            </div>
            <div className="col-4">
              <label htmlFor="projectdomain">Project Domain</label>
              <div className={styles.select_wrapper}>
                <select
                  id="projectdomain"
                  className="form-control"
                  required
                  value={projectDomain}
                  onChange={(e) => setProjectDomain(e.target.value)}
                >
                  <option selected disabled value="">
                    Choose...
                  </option>
                  {ProjectDomainOptions.map((p) => (
                    <option value={p} key={p}>
                      {p}
                    </option>
                  ))}
                </select>
                {projectDomain !== "" && (
                  <span
                    className={styles.clear_selection}
                    onClick={() => setProjectDomain("")}
                  >
                    &times;
                  </span>
                )}
              </div>
            </div>
            <div className="col-4">
              <label htmlFor="existingproject">Existing Project</label>
              <div className={styles.select_wrapper}>
                <select
                  id="existingproject"
                  className="form-control"
                  required
                  value={existingProject}
                  onChange={(e) => setExistingProject(e.target.value)}
                >
                  <option selected disabled value="">
                    Choose...
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                {existingProject !== "" && (
                  <span
                    className={styles.clear_selection}
                    onClick={() => setExistingProject("")}
                  >
                    &times;
                  </span>
                )}
              </div>
            </div>
            <div className="col-4">
              <label htmlFor="region">Region</label>
              <div className={styles.select_wrapper}>
                <select
                  id="region"
                  className="form-control"
                  required
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  <option selected disabled value="">
                    Choose...
                  </option>
                  {RegionOptions.map((r) => (
                    <option value={r} key={r}>
                      {r}
                    </option>
                  ))}
                </select>
                {region !== "" && (
                  <span
                    className={styles.clear_selection}
                    onClick={() => {
                      setRegion("");
                      setCountry("");
                      setCity("");
                      setStatesList([]);
                    }}
                  >
                    &times;
                  </span>
                )}
              </div>
            </div>

            {/* Conditional rendering based on Region */}
            {region === "North America" ? (
              <>
                <div className="col-4">
                  <label htmlFor="country">Country</label>
                  <div className={styles.select_wrapper}>
                    <select
                      id="country"
                      className="form-control"
                      required
                      value={country}
                      onChange={countryChangeHandler2}
                    >
                      <option selected disabled value="">
                        Select a country
                      </option>
                      <option value="USA">USA</option>
                      <option value="Canada">Canada</option>
                    </select>
                    {country !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={() => {
                          setCountry("");
                          setState("");
                          setStatesList([]);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="state">State</label>
                  <div className={styles.select_wrapper}>
                    <select
                      id="state"
                      className="form-control"
                      required
                      value={state}
                      onChange={stateChangeHandler}
                    >
                      <option selected disabled value="">
                        Select a state
                      </option>
                      {statesList.map((state, i2) => (
                        <option value={state} key={i2}>
                          {state}
                        </option>
                      ))}
                    </select>
                    {state !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={() => setState("")}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-4">
                  <label htmlFor="country">Country</label>
                  <div className={styles.select_wrapper}>
                    <select
                      id="country"
                      className="form-control"
                      required
                      value={country}
                      onChange={countryChangeHandler}
                    >
                      <option selected disabled value="">
                        Select a country
                      </option>
                      {Object.keys(CountryCity).map((country, i2) => (
                        <option value={country} key={i2}>
                          {country}
                        </option>
                      ))}
                    </select>
                    {country !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={() => {
                          setCountry("");
                          setCity("");
                          setCityList([]);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="city">City</label>
                  <div className={styles.select_wrapper}>
                    <select
                      id="city"
                      className="form-control"
                      required
                      value={city}
                      onChange={cityChangeHandler}
                    >
                      <option selected disabled value="">
                        Select a city
                      </option>
                      {cityList.map((city, i2) => (
                        <option value={city} key={i2}>
                          {city}
                        </option>
                      ))}
                    </select>
                    {city !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={() => setCity("")}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="col-4">
              <label htmlFor="crmid">CRM ID</label>
              <input
                value={crm}
                onChange={(e) => setCrm(e.target.value)}
                type="text"
                className="form-control"
                id="crmid"
                placeholder="CRM ID"
                required
              />
            </div>
            <div className="col-4">
              <label htmlFor="zohoprojid">Zoho Project ID</label>
              <input
                value={zohoprojid}
                onChange={(e) => setZohoprojid(e.target.value)}
                type="text"
                className="form-control"
                id="zohoiprojd"
                placeholder="Zoho Project ID"
              />
            </div>
            <div className="col-4">
              <label htmlFor="clientpartner">
                Client Partner (Account Manager)
              </label>
              <input
                value={clientPartner}
                onChange={(e) => setClientPartner(e.target.value)}
                type="text"
                className="form-control"
                id="clientpartner"
                placeholder="Client Partner (Account Manager)"
                required
              />
            </div>

            <div className="col-4">
              <label htmlFor="accountoperations">
                Account operations (Sales Representative)
              </label>
              <input
                value={accountoperations}
                onChange={(e) => setAccountoperations(e.target.value)}
                type="text"
                className="form-control"
                id="accountoperations"
                placeholder="Account operations (Sales Representative)"
                required
              />
            </div>
            <div className="col-4">
              <label htmlFor="delieverypartner">
                Delivery Partner (Tech Lead)
              </label>
              <input
                value={delieverypartner}
                onChange={(e) => setDelieverypartner(e.target.value)}
                type="text"
                className="form-control"
                id="delieverypartner"
                placeholder="Delivery Partner (Tech Lead)"
              />
            </div>
            <div className="col-4">
              <label htmlFor="comptencypartner">
                Competency Partner (Presales Solution Architect)
              </label>
              <input
                value={comptencypartner}
                onChange={(e) => setComptencypartner(e.target.value)}
                type="text"
                className="form-control"
                id="comptencypartner"
                placeholder="Competency Partner (Presales Solution Architect)"
                required
              />
            </div>

            <div className="col-6">
              <label htmlFor="projecttype">Project Type</label>
              <div className={styles.select_wrapper}>
                <select
                  id="projecttype"
                  className="form-control"
                  required
                  value={projecttype}
                  onChange={(e) => setProjecttype(e.target.value)}
                >
                  <option selected disabled value="">
                    Choose...
                  </option>
                  {ProjectTypeOptions.map((p) => (
                    <option value={p} key={p}>
                      {p}
                    </option>
                  ))}
                </select>
                {projecttype !== "" && (
                  <span
                    className={styles.clear_selection}
                    onClick={() => setProjecttype("")}
                  >
                    &times;
                  </span>
                )}
              </div>
            </div>
            <div className="col-6">
              <label htmlFor="engagementtype">Engagement Type</label>
              <div className={styles.select_wrapper}>
                <select
                  id="engagementtype"
                  className="form-control"
                  required
                  value={engagementtype}
                  onChange={(e) => setEngagementtype(e.target.value)}
                >
                  <option selected disabled value="">
                    Choose...
                  </option>
                  {EngagementTypeOptions.map((e) => (
                    <option value={e} key={e}>
                      {e}
                    </option>
                  ))}
                </select>
                {engagementtype !== "" && (
                  <span
                    className={styles.clear_selection}
                    onClick={() => setEngagementtype("")}
                  >
                    &times;
                  </span>
                )}
              </div>
            </div>
            <div className="col-4">
              <label htmlFor="technical">Technical Use Case Description</label>
              <textarea
                rows="3"
                value={technicaUseCase}
                onChange={(e) => setTechnicaUseCase(e.target.value)}
                type="text"
                className="form-control"
                id="technical"
                placeholder="Technical Use Case"
                required
              />
            </div>
            <div className="col-4">
              <label htmlFor="buisness">Business Use Case Description</label>
              <textarea
                rows="3"
                value={buisness}
                onChange={(e) => setBuisness(e.target.value)}
                type="text"
                className="form-control"
                id="buisness"
                placeholder="Business Use Case Description"
                required
              />
            </div>
            <div className="col-4">
              <label htmlFor="proposedtechnical">
                Proposed Techical Solution Description
              </label>
              <textarea
                rows="3"
                value={proposedtechnical}
                onChange={(e) => setProposedtechnical(e.target.value)}
                type="text"
                className="form-control"
                id="proposedtechnical"
                placeholder="Proposed Technical Solution Description"
                required
              />
            </div>
            <div className="col-12">
              <label htmlFor="architecture">Architecture Document Upload</label>
              <input
                value={architectureDoc}
                onChange={(e) => setArchitectureDoc(e.target.value)}
                type="text"
                className="form-control"
                id="architecture"
                placeholder="Sharable link"
                required
              />
            </div>
            <div className="col-12">
              <label htmlFor="proposedtechnicalsolution">
                Proposed Techical Solution Document Upload
              </label>
              <input
                value={proposedtechnicalsolution}
                onChange={(e) => setProposedtechnicalsolution(e.target.value)}
                type="text"
                className="form-control"
                id="proposedtechnicalsolution"
                placeholder="Sharable link"
              />
            </div>
            <div className="col-12">
              <label htmlFor="remarks">Remarks</label>
              <textarea
                rows="3"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                type="text"
                className="form-control"
                id="remarks"
                placeholder="Remarks"
              />
            </div>
          </>

          <div className="col-12">
            <h5>Roles *</h5>

            {roles.map((role, index) => (
              <div className="s-role-conteiner row" key={index}>
                <div className="col-4">
                  <label htmlFor="role">Role</label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="role"
                      id="role"
                      className="form-control"
                      value={role.role}
                      required
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      {RolesOption.map((r) => (
                        <option value={r} key={r}>
                          {r}
                        </option>
                      ))}
                    </select>
                    {role.role !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["role"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>

                {/* conditional rendering based on role */}
                {role.role === "Other" && (
                  <div className="col-4">
                    <label htmlFor="otherRoles">Other Roles</label>
                    <input
                      name="otherRoles"
                      type="text"
                      className="form-control"
                      id="otherRoles"
                      placeholder="Other Roles"
                      onChange={(e) => handleChangeRoles(e, index)}
                    />
                  </div>
                )}

                <div className="col-4">
                  <label htmlFor="techSkills">
                    Tech Skills Summary: Comma separated
                  </label>
                  <input
                    name="techSkills"
                    type="text"
                    className="form-control"
                    id="techSkills"
                    placeholder="Tech Skill Summary"
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="mode">Mode</label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="mode"
                      id="mode"
                      className="form-control"
                      value={role.mode}
                      required
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option value="Billable">Billable</option>
                      <option value="Non-Billable">Non-Billable</option>
                    </select>
                    {role.mode !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["mode"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="workExperience">Work Experience</label>
                  <input
                    name="workExperience"
                    type="text"
                    className="form-control"
                    id="workExperience"
                    placeholder="Work Experience"
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="ctWorkExperience">CT Work Experience</label>
                  <input
                    name="ctWorkExperience"
                    type="text"
                    className="form-control"
                    id="ctWorkExperience"
                    placeholder="CT Work Experience"
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="headCount">Head Count</label>
                  <input
                    name="headCount"
                    type="text"
                    className="form-control"
                    id="headCount"
                    placeholder="Head Count"
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="worklocation">Work Location</label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="workLocation"
                      id="worklocation"
                      className="form-control"
                      value={role.workLocation}
                      required
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option value="Onsite">Onsite</option>
                      <option value="Offsite">Offsite</option>
                    </select>
                    {role.workLocation !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["workLocation"] = "";
                          newRoles[index]["region"] = "";
                          newRoles[index]["country"] = "";
                          newRoles[index]["city"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                {/* conditional rendering based on Work Location */}
                {role.workLocation === "Onsite" && (
                  <>
                    <div className="col-4">
                      <label htmlFor="region1">Region</label>
                      <div className={styles.select_wrapper}>
                        <select
                          name="region"
                          id="region1"
                          className="form-control"
                          required
                          onChange={(e) => handleChangeRoles(e, index)}
                        >
                          <option selected disabled value="">
                            Choose...
                          </option>
                          {RoleRegionOptions.map((r) => (
                            <option value={r} key={r}>
                              {r}
                            </option>
                          ))}
                        </select>
                        {role.region !== "" && (
                          <span
                            className={styles.clear_selection}
                            onClick={(e) => {
                              var newRoles = [...roles];
                              newRoles[index]["region"] = "";
                              setRoles(newRoles);
                            }}
                          >
                            &times;
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <label htmlFor="country">Country</label>
                      <div className={styles.select_wrapper}>
                        <select
                          name="country"
                          id="country"
                          className="form-control"
                          required
                          onChange={(e) => countryChangeHandler1(e, index)}
                        >
                          <option selected disabled value="">
                            Select a country
                          </option>
                          {Object.keys(CountryCity).map((country, i2) => (
                            <option value={country} key={i2}>
                              {country}
                            </option>
                          ))}
                        </select>
                        {role.country !== "" && (
                          <span
                            className={styles.clear_selection}
                            onClick={(e) => {
                              var newRoles = [...roles];
                              newRoles[index]["country"] = "";
                              setRoles(newRoles);
                            }}
                          >
                            &times;
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-4">
                      <label htmlFor="city1">City</label>
                      <div className={styles.select_wrapper}>
                        <select
                          name="city"
                          id="city1"
                          className="form-control"
                          required
                          onChange={(e) => cityChangeHandler1(e, index)}
                        >
                          <option selected disabled value="">
                            Select a city
                          </option>
                          {newCityList.map((city, i2) => (
                            <option value={city} key={i2}>
                              {city}
                            </option>
                          ))}
                        </select>
                        {role.city !== "" && (
                          <span
                            className={styles.clear_selection}
                            onClick={(e) => {
                              var newRoles = [...roles];
                              newRoles[index]["city"] = "";
                              setRoles(newRoles);
                            }}
                          >
                            &times;
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {role.workLocation === "Offsite" && (
                  <div className="col-4">
                    <label htmlFor="officeLocation">Office Location</label>
                    <div className={styles.select_wrapper}>
                      <select
                        name="officeLocation"
                        id="officeLocation"
                        className="form-control"
                        value={role.officeLocation}
                        required
                        onChange={(e) => handleChangeRoles(e, index)}
                      >
                        <option selected disabled value="">
                          Choose...
                        </option>
                        {officeLocationOptions.map((o) => (
                          <option value={o} key={o}>
                            {o}
                          </option>
                        ))}
                      </select>
                      {role.officeLocation !== "" && (
                        <span
                          className={styles.clear_selection}
                          onClick={(e) => {
                            var newRoles = [...roles];
                            newRoles[index]["officeLocation"] = "";
                            setRoles(newRoles);
                          }}
                        >
                          &times;
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-4">
                  <label htmlFor="employmenttype">
                    Preferred Employment Type
                  </label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="preferredEmploymentType"
                      id="employmenttype"
                      className="form-control"
                      value={role.preferredEmploymentType}
                      required
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      {employmenttypeOptions.map((e) => (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      ))}
                    </select>
                    {role.preferredEmploymentType !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["preferredEmploymentType"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="shifttime">Shift Time</label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="ShiftTime"
                      id="shifttime"
                      className="form-control"
                      value={role.ShiftTime}
                      required
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      {shifttimeOptions.map((s) => (
                        <option value={s} key={s}>
                          {s}
                        </option>
                      ))}
                    </select>
                    {role.ShiftTime !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["ShiftTime"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="OverlapRequired">Overlap Required</label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="OverlapRequired"
                      id="OverlapRequired"
                      className="form-control"
                      value={role.OverlapRequired}
                      required
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {role.OverlapRequired !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["OverlapRequired"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="InterviewMandatory">
                    Interview Mandatory (Client / Partner)
                  </label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="InterviewMandatory"
                      id="InterviewMandatory"
                      className="form-control"
                      value={role.InterviewMandatory}
                      required
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {role.InterviewMandatory !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["InterviewMandatory"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="BudgetRange">
                    Budget Range ( CTC per month/ per hour )
                  </label>
                  <input
                    name="BudgetRange"
                    type="text"
                    className="form-control"
                    id="BudgetRange"
                    placeholder="Budget Range ( CTC per month/ per hour )"
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="MappingTimePeriod">Mapping Time Period</label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="MappingTimePeriod"
                      id="MappingTimePeriod"
                      className="form-control"
                      required
                      value={role.MappingTimePeriod}
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option value="critical-lessthan-2Days">
                        {"Critical ( < 2 Days )"}
                      </option>
                      <option value="urgent-lessthan-1Week">
                        {"Urgent ( < 1 Week )"}
                      </option>
                      <option value="2-3Weeks">2-3 weeks</option>
                      <option value="lessthan-4Weeks">&lt; 4 weeks</option>
                    </select>
                    {role.MappingTimePeriod !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["MappingTimePeriod"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="Utilization">
                    Utilization (as per man hours per day)
                  </label>
                  <input
                    name="Utilization"
                    type="text"
                    className="form-control"
                    id="Utilization"
                    placeholder="Utilization (as per man hours per day)"
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="MinimumEngagementPeriod">
                    Minimum Engagement Period (anticipated)
                  </label>
                  <input
                    name="MinimumEngagementPeriod"
                    type="text"
                    className="form-control"
                    id="MinimumEngagementPeriod"
                    placeholder="Minimum Engagement Period"
                    required
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="MaxEngagementPeriod">
                    Max Engagement Period (anticipated)
                  </label>
                  <input
                    name="MaxEngagementPeriod"
                    type="text"
                    className="form-control"
                    id="MaxEngagementPeriod"
                    placeholder="Max Engagement Period"
                    required
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="EngagementTentativeStartDate">
                    Engagement Tentative Start Date
                  </label>
                  <input
                    name="EngagementTentativeStartDate"
                    type="date"
                    id="EngagementTentativeStartDate"
                    className="form-control"
                    min="2015-01-01"
                    max="2050-12-31"
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div
                  className={
                    role.workLocation === "Offsite" ? "col-6" : "col-4"
                  }
                >
                  <label htmlFor="EngagementTentativeEndDate">
                    Engagement Tentative End Date
                  </label>
                  <input
                    name="EngagementTentativeEndDate"
                    type="date"
                    id="EngagementTentativeEndDate"
                    className="form-control"
                    min="2015-01-01"
                    max="2050-12-31"
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div
                  className={
                    role.workLocation === "Offsite" ? "col-6" : "col-12"
                  }
                >
                  <label htmlFor="CertificationRequirenment">
                    Certification Requirenment
                  </label>
                  <div className={styles.select_wrapper}>
                    <select
                      name="CertificationRequirenment"
                      id="CertificationRequirenment"
                      className="form-control"
                      value={role.CertificationRequirenment}
                      required
                      onChange={(e) => handleChangeRoles(e, index)}
                    >
                      <option selected disabled value="">
                        Choose...
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {role.CertificationRequirenment !== "" && (
                      <span
                        className={styles.clear_selection}
                        onClick={(e) => {
                          var newRoles = [...roles];
                          newRoles[index]["CertificationRequirenment"] = "";
                          setRoles(newRoles);
                        }}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="ResourceInTypicalDay">
                    Resource could be doing in a Typical Day
                  </label>
                  <textarea
                    rows="3"
                    name="ResourceInTypicalDay"
                    type="text"
                    className="form-control"
                    id="ResourceInTypicalDay"
                    placeholder="Resource could be doing in a Typical Day"
                    required
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="TechnicalSkills">Technical Skills</label>
                  <textarea
                    rows="3"
                    name="TechnicalSkills"
                    type="text"
                    className="form-control"
                    id="TechnicalSkills"
                    placeholder="Technical Skills"
                    required
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="BehaviouralSkills">Behavioural Skills</label>
                  <textarea
                    rows="3"
                    name="BehaviouralSkills"
                    type="text"
                    className="form-control"
                    id="BehaviouralSkills"
                    placeholder="Behavioural Skills"
                    required
                    onChange={(e) => handleChangeRoles(e, index)}
                  />
                </div>
                {roles.length > 1 && (
                  <div className="d-flex justify-content-end">
                    <div
                      className="btn btn-primary add-form-btn"
                      id="add-form-btn"
                      onClick={(e) => removeRole(e, index)}
                    >
                      Remove Role
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div
            className="btn btn-success border w-100"
            id="add-form-btn"
            onClick={addRole}
          >
            Add Another Role
          </div>

          <div className="d-flex justify-content-sm-between mt-4 mb-3">
            <Link to="/" className="btn btn-secondary add-form-btn">
              Back
            </Link>
            <button
              type="submit"
              className="btn btn-primary add-form-btn"
              id="add-form-btn"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ResourceForm;
